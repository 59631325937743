<template>
  <div class="mt-3 mt-lg-0">
    <feed-new-post v-if="cancreate == null"></feed-new-post>
    <feed-posts></feed-posts>
  </div>
</template>

<script>
export default {
  props: ["origin", "hashtag", "cancreate"],

  beforeMount() {
    this.auth_user = {
      id: this.$attrs.user_id,
      name: this.$attrs.user_name,
      avatar: this.$attrs.user_avatar,
      profile_link: this.$attrs.user_link,
      jobTitle: this.$attrs.user_job_title,
      company: this.$attrs.user_company,
      type: this.$attrs.user_type,
    };
    this.originEntity = this.auth_user;

    this.destinationId = this.auth_user.id;
    if (this.origin == "company") {
      this.originEntity = {
        id: this.$attrs.origin_id,
        name: this.$attrs.origin_name,
        avatar: this.$attrs.origin_avatar,
        profile_link: this.$attrs.origin_id,
        follower_count: this.$attrs.origin_follower_count,
      };
      this.destinationId = this.$attrs.origin_id;
    } else if (this.origin == "group" || this.origin == "event") {
      this.destinationId = this.$attrs.origin_id;
    }
  },

  data() {
    return {
      posts: [],
      stopLoading: false,
      originEntity: null,
      destinationId: null,
    };
  },
};
</script>
