var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal modal-lm fade in",
        attrs: {
          id: "modalFeedShare" + _vm.shareId,
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "button",
                {
                  ref: "closeModal",
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: { click: _vm.kill }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { ref: "modalBody", staticClass: "modal-body" },
                [
                  _c("div", { staticClass: "d-flex p-3" }, [
                    _c("div", [
                      _c("img", {
                        staticClass: "rounded-circle mt-1",
                        attrs: {
                          src: this.authUserAvatar,
                          alt: this.authUserName,
                          width: "50",
                          height: "50"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-column ml-3" }, [
                      _c("span", { staticClass: "text-dark font-weight-500" }, [
                        _vm._v(_vm._s(this.authUserName))
                      ]),
                      _vm._v(" "),
                      this.authUserJobTitle
                        ? _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(this.authUserJobTitle)
                            ),
                            this.authUserJobTitle && this.authUserCompany
                              ? _c(
                                  "span",
                                  { staticClass: "line-height-normal text-xs" },
                                  [_vm._v(", ")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      this.authUserCompany
                        ? _c("span", { staticClass: "main-color" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(this.authUserCompany) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("textarea-autosize", {
                    ref: "content",
                    staticClass: "comment-editor border-0 w-100 py-2 px-3",
                    attrs: {
                      placeholder: _vm.trans("Write something"),
                      "min-height": 100,
                      "max-height": 450
                    },
                    model: {
                      value: _vm.content,
                      callback: function($$v) {
                        _vm.content = $$v
                      },
                      expression: "content"
                    }
                  }),
                  _vm._v(" "),
                  _vm.shareType == "post"
                    ? _c("feed-post-body", {
                        staticClass: "share-original",
                        attrs: {
                          post: _vm.post.share || _vm.post,
                          shared: true
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shareType == "event"
                    ? _c("div", { staticClass: "share-original m-3" }, [
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: { src: _vm.dataShare.banner }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex p-3" }, [
                          _c("a", { attrs: { href: _vm.dataShare.link } }, [
                            _c("img", {
                              staticClass: "rounded-circle mt-1",
                              attrs: {
                                src: _vm.dataShare.logo,
                                width: "50",
                                height: "50"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column ml-3 text-xs" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-nm truncate-2",
                                  attrs: { href: _vm.dataShare.link }
                                },
                                [_vm._v(_vm._s(_vm.dataShare.title))]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.dataShare.date))]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.dataShare.location))
                              ])
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shareType == "job"
                    ? _c("div", { staticClass: "share-original m-3" }, [
                        _c("div", { staticClass: "d-flex p-3" }, [
                          _c("a", { attrs: { href: _vm.dataShare.link } }, [
                            _c("img", {
                              staticClass: "rounded-circle mt-1",
                              attrs: {
                                src: _vm.dataShare.logo,
                                width: "50",
                                height: "50"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column ml-3 text-xs" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-nm truncate-2",
                                  attrs: { href: _vm.dataShare.link }
                                },
                                [_vm._v(_vm._s(_vm.dataShare.title))]
                              ),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.dataShare.company))
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.dataShare.location))
                              ])
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shareType == "company"
                    ? _c("div", { staticClass: "share-original m-3" }, [
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: { src: _vm.dataShare.banner }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex p-3" }, [
                          _c("a", { attrs: { href: _vm.dataShare.link } }, [
                            _c("img", {
                              staticClass: "rounded-circle mt-1",
                              attrs: {
                                src: _vm.dataShare.logo,
                                width: "50",
                                height: "50"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column ml-3 text-xs" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-nm truncate-2",
                                  attrs: { href: _vm.dataShare.link }
                                },
                                [_vm._v(_vm._s(_vm.dataShare.title))]
                              ),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.dataShare.industry))
                              ])
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shareType == "group"
                    ? _c("div", { staticClass: "share-original m-3" }, [
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: { src: _vm.dataShare.banner }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex p-3" }, [
                          _c("a", { attrs: { href: _vm.dataShare.link } }, [
                            _c("img", {
                              staticClass: "rounded-circle mt-1",
                              attrs: {
                                src: _vm.dataShare.logo,
                                width: "50",
                                height: "50"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column ml-3 text-xs" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-nm truncate-2",
                                  attrs: { href: _vm.dataShare.link }
                                },
                                [_vm._v(_vm._s(_vm.dataShare.title))]
                              ),
                              _vm._v(" "),
                              _vm.dataShare.users > 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dataShare.users) +
                                        _vm._s(_vm.trans(" members"))
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shareType == "link"
                    ? _c("div", { staticClass: "share-original m-3" }, [
                        _c(
                          "a",
                          {
                            class: {
                              "d-flex": true,
                              "flex-column":
                                _vm.dataShare.image_orientation == "landscape"
                            },
                            attrs: { href: _vm.dataShare.url, target: "_blank" }
                          },
                          [
                            _vm.dataShare.image != ""
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "w-25":
                                        _vm.dataShare.image_orientation ==
                                        "portrait"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-fluid",
                                      attrs: {
                                        src: _vm.dataShare.image,
                                        loading: "lazy"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "pb-1 pt-3 px-3",
                                class: {
                                  "w-75":
                                    _vm.dataShare.image_orientation ==
                                    "portrait"
                                }
                              },
                              [
                                _c("p", { staticClass: "text-lg truncate-2" }, [
                                  _vm._v(_vm._s(_vm.dataShare.title))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-xs text-muted truncate-2"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.dataShare.url) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                !_vm.posting
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-lm btn-sm",
                        attrs: { type: "submit", disabled: _vm.disablePost },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.sharePost($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.trans("Post")) +
                            "\n          "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.posting
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-lm btn-sm",
                        attrs: { disabled: "" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.trans("Please wait")) +
                            "\n            "
                        ),
                        _c("i", { staticClass: "fas fa-circle-notch fa-spin" })
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }