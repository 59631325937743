<template>
  <vue-bootstrap-typeahead
    :data="values"
    v-model="value"
    :serializer="(s) => s.text"
    :placeholder="placeholder"
    inputClass="chatbot-autocomplete"
    @hit="handleHit"
    @keydown.enter.prevent.native="handleEnter"
  />
</template>

<script>
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

export default {
  name: "ChatbotAutocomplete",

  props: ["action", "placeholder"],

  data() {
    return {
      timeout: null,
      values: [],
      value: "",
    };
  },

  components: {
    VueBootstrapTypeahead,
  },

  methods: {
    getData: function (query) {
      axios
        .post(this.action.replace(":query", query))
        .then((response) => {
          this.values = response.data.results;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selectValue: function () {},

    handleHit: function (evt) {
      if (typeof this.value !== "undefined") {
        this.$emit("input", evt.text);
      }
      this.value = evt.text;
      this.$emit("hit", evt.data);
    },

    handleEnter: function (evt) {
      if (typeof this.value !== "undefined") {
        this.$emit("input", "");
      }
      this.value = "";
      this.$emit("hit", evt.data);
    },
  },

  watch: {
    value: function (val) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (val != "") {
          this.getData(val);
        }
      }, 300);
    },
  },
};
</script>

<style>
.vbt-autcomplete-list {
  bottom: 60px;
}
.chatbot-autocomplete {
  height: 45px;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 2px;
  padding-left: 10px;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4f68e8;
  border-color: #4f68e8;
}
.vbst-item.list-group-item.list-group-item-action {
  font-size: 13px;
}
</style>
