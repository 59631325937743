var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notifications", {
    attrs: { position: "bottom right" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(props) {
          return [
            _c(
              "div",
              {
                class: [
                  "vue-notification-template vue-notif  d-flex justify-content-between",
                  props.item.type
                ]
              },
              [
                _c("div", { staticClass: "notification-content" }, [
                  _c("div", { staticClass: "notification-title" }, [
                    _vm._v(_vm._s(props.item.title))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "notification-content",
                    domProps: { innerHTML: _vm._s(props.item.text) }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "notification-close",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return props.close($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }