var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feed-posts mt-4" },
    [
      _vm._l(_vm.$parent.posts, function(post) {
        return !_vm.error
          ? _c("feed-post", { key: post.id, attrs: { post: post } })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.$parent.posts.length == 0 && !_vm.loading
        ? _c("div", { staticClass: "feed-posts-no-results mx-2 mx-lg-0" }, [
            this.$parent.origin != "user"
              ? _c("p", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.trans("We have no posts to display right now.")
                      ) +
                      "\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.$parent.origin == "user" && !_vm.$parent.hashtag
              ? _c("p", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.trans("Welcome to LeaddMe!")) +
                      "\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.$parent.origin == "user" &&
            this.$parent.originEntity.type == "member"
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.trans(
                        "Follow professionals, recruiters and companies in your industry for updates in your newsfeed or join a group to get started. Create your first post and share with the community."
                      )
                    )
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.$parent.origin == "user" &&
            this.$parent.originEntity.type == "recruiter"
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.trans(
                        "Follow companies for hot leads and industry insights or join a group to get started. Create a post or post your first job and share with the community."
                      )
                    )
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$parent.hashtag
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.trans(
                        "You can create a post to share with the community!"
                      )
                    )
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.trans(
                    "We cannot display your newsfeed at the moment. Try to refresh this page."
                  )
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading && !_vm.error
        ? _c("div", { staticClass: "spinner" }, [
            _c("div", { staticClass: "bounce1" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce2" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce3" })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }