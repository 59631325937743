<template>
  <div class="feed-post" v-bind:class="[display_comments ? '' : 'mb-4']">
    <!-- DISPLAY POST BODY -->
    <feed-post-body :post="post" :actions="true" :edit="edit" />

    <!-- EDIT POST CONTENT -->
    <div v-if="edit" class="p-3" ref="divEdit">
      <textarea-autosize
        ref="editContent"
        v-model="editContent"
        class="comment-editor w-100 p-2"
        :placeholder="trans('Write something')"
        :max-height="450"
        @input="disableEdit"
      />
      <div class="text-right pt-2">
        <button class="btn btn-sm" @click.prevent="edit = false">
          {{ trans("Cancel") }}
        </button>
        <button
          ref="btnEdit"
          class="btn btn-lm btn-sm"
          @click.prevent="editPost"
        >
          {{ trans("Save") }}
        </button>
      </div>
    </div>

    <!-- DISPLAY SHARED CONTENT (IF ANY) -->
    <div v-if="post.share">
      <feed-post-body
        v-if="post.share.type == 'post'"
        class="share-original m-3"
        :post="post.share"
        :shared="true"
      />
      <div v-if="post.share.type == 'event'" class="share-original m-3">
        <img :src="post.share.banner" class="img-fluid" loading="lazy" />
        <div class="d-flex p-3">
          <a :href="post.share.link">
            <img
              :src="post.share.logo"
              width="50"
              height="50"
              class="rounded-circle mt-1"
              loading="lazy"
            />
          </a>
          <div class="d-flex flex-column ml-3 text-xs">
            <a :href="post.share.link" class="text-nm truncate-2">{{
              post.share.title
            }}</a>
            <span>{{ post.share.date }}</span>
            <span>{{ post.share.location }}</span>
          </div>
        </div>
      </div>
      <div v-if="post.share.type == 'job'" class="share-original m-3">
        <div class="d-flex p-3">
          <a :href="post.share.link">
            <img
              :src="post.share.logo"
              width="50"
              height="50"
              class="rounded-circle mt-1"
              loading="lazy"
            />
          </a>
          <div class="d-flex flex-column ml-3 text-xs">
            <a :href="post.share.link" class="text-nm truncate-2">{{
              post.share.title
            }}</a>
            <span>{{ post.share.company }}</span>
            <span>{{ post.share.location }}</span>
          </div>
        </div>
      </div>
      <div v-if="post.share.type == 'company'" class="share-original m-3">
        <img :src="post.share.banner" class="img-fluid" loading="lazy" />
        <div class="d-flex p-3">
          <a :href="post.share.link">
            <img
              :src="post.share.logo"
              width="50"
              height="50"
              class="rounded-circle mt-1"
              loading="lazy"
            />
          </a>
          <div class="d-flex flex-column ml-3 text-xs">
            <a :href="post.share.link" class="text-nm truncate-2">{{
              post.share.title
            }}</a>
            <span>{{ post.share.industry }}</span>
          </div>
        </div>
      </div>
      <div v-if="post.share.type == 'group'" class="share-original m-3">
        <img :src="post.share.banner" class="img-fluid" loading="lazy" />
        <div class="d-flex p-3">
          <a :href="post.share.link">
            <img
              :src="post.share.logo"
              width="50"
              height="50"
              class="rounded-circle mt-1"
              loading="lazy"
            />
          </a>
          <div class="d-flex flex-column ml-3 text-xs">
            <a :href="post.share.link" class="text-nm truncate-2">{{
              post.share.title
            }}</a>
            <span v-if="post.share.users > 1"
              >{{ post.share.users }}{{ trans(" members") }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="post.share.type == 'link'" class="share-original m-3 bg-gray">
        <a
          :href="post.share.url"
          target="_blank"
          :class="{
            'd-flex': true,
            'flex-column': post.share.image_orientation == 'landscape',
          }"
        >
          <div
            v-if="post.share.image != ''"
            :class="{
              'w-25': post.share.image_orientation == 'portrait',
            }"
          >
            <img
              :src="post.share.image"
              class="w-100 img-fluid"
              loading="lazy"
            />
          </div>
          <div
            class="pb-1 pt-3 px-3"
            :class="{
              'w-75': post.share.image_orientation == 'portrait',
            }"
          >
            <p class="text-lg truncate-2">{{ post.share.title }}</p>
            <p class="text-xs text-muted truncate-2">
              {{ post.share.url }}
            </p>
          </div>
        </a>
      </div>
    </div>
    <div v-else-if="post.share == false" class="share-original m-3 p-3">
      {{ trans("Shared post was deleted by the author") }}
    </div>

    <!-- POST ACTIONS LIKE / COMMENT / SHARE -->
    <div class="post-actions p-3 mt-3 d-flex d-lg-block">
      <a href="#" class="mr-lg-4 flex-1" @click.prevent="likePost">
        <i v-if="post.like" class="fas fa-heart mr-1"></i>
        <i v-else class="far fa-heart mr-1"></i>
        {{ trans("Like") }}
      </a>
      <a
        href="#"
        @click.prevent="showComments = !showComments"
        class="flex-1 text-center"
      >
        <i class="far fa-comment-alt mr-1"></i>
        {{ trans("Comment") }}
      </a>
      <a
        href="#"
        class="ml-lg-4 flex-1 text-right"
        @click.prevent="enableShare = true"
        data-toggle="modal"
        :data-target="'#modalFeedShare' + shareId"
      >
        <i class="fas fa-share-alt mr-1"></i>
        <span class="align-middle">{{ trans("Share") }}</span>
      </a>
    </div>

    <!-- POST VIEWS COUNT -->
    <div
      v-if="post.can_view_stats && post.views > 0"
      class="post-counts pt-3 px-3"
      v-bind:class="[post.likes > 0 || post.comments > 0 ? 'mb-n2' : 'pb-3']"
    >
      <a href="#" data-toggle="modal" :data-target="'#modalPostView' + post.id">
        <i class="fas fa-chart-bar"></i>
        {{ post.views }}{{ trans(" views on your post") }}
      </a>
    </div>

    <!-- LIKES AND COMMENTS COUNTS -->
    <div
      class="post-counts d-flex p-3"
      v-if="!post.self || (post.self && (post.likes > 0 || post.comments > 0))"
    >
      <div class="text-dark font-weight-bold">
        <span v-if="post.likes == 0"></span>
        <span v-else-if="post.likes < 2"
          >{{ post.likes }}{{ trans(" like") }}</span
        >
        <span v-else>{{ post.likes }}{{ trans(" likes") }}</span>
      </div>
      <div
        class="ml-auto cursor-pointer load-comments"
        @click="showComments = !showComments"
      >
        <span v-if="post.comments == 0">{{
          trans("Be the first to comment")
        }}</span>
        <span v-else-if="post.comments < 2"
          >{{ post.comments }}{{ trans(" comment") }}</span
        >
        <span v-else>{{ post.comments }}{{ trans(" comments") }}</span>
      </div>
    </div>

    <feed-comments v-if="showComments" />

    <!-- MODAL FOR SHARING POST, JOB OR EVENT -->
    <feed-share
      :post="post"
      :share-type="this.shareType"
      :share-id="this.shareId"
      :auth-user-name="this.auth_user.name"
      :auth-user-avatar="this.auth_user.avatar"
      :auth-user-job-title="this.auth_user.jobTitle"
      :auth-user-company="this.auth_user.company"
      :share-data="this.shareData"
    ></feed-share>

    <!-- MODAL FOR POST VIEWS -->
    <post-views
      v-if="post.can_view_stats && post.views > 0"
      :post="post"
    ></post-views>
  </div>
</template>

<script>
export default {
  props: ["post", "current_user", "display_comments"],

  data() {
    return {
      url: window.origin,
      edit: false,
      showComments: false,
      auth_user: null,
      comments: [],
      commentsLoaded: false,
      enableShare: false,
      shareId: null,
      shareType: null,
      shareData: {},
      editContent: this.post.content_raw,
    };
  },

  beforeMount() {
    if (typeof this.current_user === "undefined") {
      this.auth_user = this.$parent.$parent.auth_user;
    } else {
      this.auth_user = this.current_user;
    }

    if (typeof this.display_comments !== "undefined") {
      this.showComments = true;
    }

    this.shareId = this.post.id;
    if (this.post.share && this.post.share.id) {
      this.shareId = this.post.share.id;
    }

    this.shareType = "post";
    if (this.post.share && this.post.share.type) {
      this.shareType = this.post.share.type;
    }

    if (this.post.share && this.post.share) {
      this.shareData = this.post.share;
    }
  },

  methods: {
    editPost() {
      this.$refs.divEdit.classList.add("disabled");
      const content = this.editContent;
      axios
        .patch("/feed/posts/" + this.post.id, {
          content: content,
        })
        .then((response) => {
          // Update post
          this.post.content = escapeHtml(content);
          this.post.content_raw = content;

          // Close form
          this.edit = false;

          // Send notification
          this.$notify({
            title: this.trans("Post successfully updated"),
            text:
              '<a href="/feed/post/' +
              response.data.id +
              '">' +
              this.trans("Click here") +
              "</a> " +
              this.trans("to view your post"),
            duration: 1000,
          });
          this.$refs.divEdit.classList.remove("disabled");
        })
        .catch((error) => {
          this.$notify({
            title: this.trans("Your post was not updated"),
            text: this.trans("Please try again later."),
            type: "error",
            duration: -1,
          });
          this.$refs.divEdit.classList.remove("disabled");
        });
    },
    disableEdit() {
      if (this.editContent.length < 2) {
        this.$refs.btnEdit.disabled = true;
      } else {
        this.$refs.btnEdit.disabled = false;
      }
    },
    likePost() {
      let action = this.post.like ? "unlike" : "like";
      this.post.like = !this.post.like;
      axios
        .post("/feed/posts/" + this.post.id + "/likes", {
          action: action,
        })
        .then((res) => {
          if (action == "like") {
            this.post.likes++;
          } else {
            this.post.likes--;
          }
        });
    },
  },
};
</script>
