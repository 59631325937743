var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-bootstrap-typeahead", {
    attrs: {
      data: _vm.values,
      serializer: function(s) {
        return s.text
      },
      placeholder: _vm.placeholder,
      inputClass: "chatbot-autocomplete"
    },
    on: { hit: _vm.handleHit },
    nativeOn: {
      keydown: function($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        $event.preventDefault()
        return _vm.handleEnter($event)
      }
    },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }