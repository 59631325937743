<template>
  <div class="post-comment d-flex">
    <a :href="comment.user.profile_link">
      <img
        :src="comment.user.avatar"
        :alt="comment.user.name"
        width="50"
        height="50"
        class="flex-shrink-0 rounded-circle mr-2"
      />
    </a>
    <div class="w-100">
      <!-- COMMENT BODY -->
      <div class="comment-body p-3">
        <div class="d-flex">
          <div>
            <a :href="comment.user.profile_link" class="user-name d-block">{{
              comment.user.name
            }}</a>
          </div>
          <div class="ml-auto text-right">
            <span class="comment-date mr-lg-1">{{ comment.date }}</span>
            <i
              class="fas fa-ellipsis-h cursor-pointer"
              data-toggle="dropdown"
              data-offset="5,10"
            ></i>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                v-if="comment.self"
                class="dropdown-item"
                href="#"
                @click.prevent="edit = true"
              >
                <i class="fas fa-pen"></i>
                {{ trans("Edit comment") }}
              </a>
              <a
                v-if="comment.deletable"
                class="dropdown-item"
                href="#"
                @click.prevent="deleteComment"
              >
                <i class="fas fa-trash"></i>
                {{ trans("Delete comment") }}
              </a>
              <a
                v-if="!comment.self"
                class="dropdown-item"
                :href="'/contact-us?report=comment&ref=' + comment.id"
              >
                <i class="far fa-flag mr-2"></i>
                {{ trans("Report") }}
              </a>
            </div>
          </div>
        </div>
        <div class="user-title mb-3 text-sm">
            <span v-if="comment.user.job_title">
                {{ comment.user.job_title }}<span v-if="comment.user.job_title && comment.user.company">, </span>
            </span>
                <span v-if="comment.user.company" class="main-color">
                {{ comment.user.company }}
            </span>
        </div>

        <div v-if="!edit">
          <span v-if="truncated">
            <span
              class="content"
              v-html="
                $options.filters.truncate(
                  getContent(),
                  textCharLimit,
                  textLineLimit
                )
              "
            ></span>
            <span
              class="cursor-pointer"
              @click.prevent="truncated = !truncated"
              >{{ trans("see more") }}</span
            >
          </span>
          <span v-else class="content" v-html="getContent()"></span>
        </div>

        <!-- COMMENT EDIT -->
        <div v-if="edit">
          <textarea-autosize
            ref="editContent"
            v-model="editContent"
            class="comment-editor w-100 py-2 px-3"
            :placeholder="trans('Write a reply')"
            rows="1"
            :max-height="300"
            @input="disableEdit"
          />
          <div class="text-right pt-2">
            <button class="btn btn-sm" @click.prevent="edit = false">
              {{ trans("Cancel") }}
            </button>
            <button
              ref="btnEdit"
              class="btn btn-lm btn-sm"
              @click.prevent="editComment"
            >
              {{ trans("Save") }}
            </button>
          </div>
        </div>
      </div>

      <!-- COMMENT ACTIONS -->
      <div class="mt-2">
        <div class="pb-2">
          <!-- COMMENT LIKE -->
          <span class="cursor-pointer ml-3" @click.prevent="likeComment">
            <i v-if="comment.like" class="fas fa-heart mr-1"></i>
            <i v-else class="far fa-heart mr-1"></i>
            <span v-if="comment.likes > 0">
              <span v-if="comment.likes < 2"
                >{{ comment.likes }}{{ trans(" like") }}</span
              >
              <span v-else>{{ comment.likes }}{{ trans(" likes") }}</span>
            </span>
          </span>

          <!-- COMMENT REPLY -->
          <span
            class="cursor-pointer ml-3"
            @click="
              toggleReply();
              loadReplies(comment);
            "
          >
            <i class="far fa-comment-alt mr-1"></i>
            <span v-if="comment.replies > 0">
              <span v-if="comment.replies < 2"
                >{{ comment.replies }}{{ trans(" reply") }}</span
              >
              <span v-else>{{ comment.replies }}{{ trans(" replies") }}</span>
            </span>
          </span>
          <div v-if="showReplies" class="d-flex mt-2">
            <span>
              <img
                :src="this.auth_user.avatar"
                class="rounded-circle mr-2"
                alt
                width="42"
              />
            </span>
            <textarea-autosize
              ref="reply"
              v-model="reply"
              class="comment-editor w-100 py-2 px-3"
              :placeholder="trans('Write a reply')"
              rows="1"
              :max-height="300"
            />
          </div>
          <div class="text-right mt-2">
            <button
              v-if="showReplies && !posting"
              class="btn btn-lm btn-sm"
              @click.prevent="postReply"
              :disabled="disablePost"
            >
              {{ trans("Reply") }}
            </button>
            <button v-if="posting" class="btn btn-lm btn-sm" disabled>
              {{ trans("Please wait") }}
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </div>
        </div>

        <!-- COMMENT REPLIES -->
        <div
          v-show="showReplies"
          v-for="reply in replies"
          v-bind:key="reply.id"
        >
          <feed-comment :comment="reply" v-bind:is-reply="true" />
        </div>
        <div
          v-if="
            replies.length > 0 &&
            comment.replies > replies.length &&
            showReplies &&
            !loading
          "
          class="text-center cursor-pointer bold-blue mb-3"
          @click.prevent="loadReplies(comment, true)"
        >
          {{ trans("Load more replies") }}
        </div>
        <div v-if="loading" class="spinner mt-1 mb-3">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: Object,
    isReply: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      edit: false,
      replies: [],
      showReplies: false,
      repliesLoaded: false,
      textCharLimit: 400,
      textLineLimit: 6,
      truncated: false,
      reply: "",
      loading: false,
      posting: false,
      auth_user: null,
      editContent: this.comment.content_raw,
    };
  },

  beforeMount() {
    let lines = (this.comment.content.match(/\r?\n|\r/g) || []).length + 1;
    this.truncated =
      this.comment.content.length > this.textCharLimit ||
      lines > this.textLineLimit;

    if (this.isReply) {
      this.auth_user = this.$parent.$parent.$parent.auth_user;
    } else {
      this.auth_user = this.$parent.$parent.auth_user;
    }
  },

  methods: {
    loadReplies(parent, loadMore = false) {
      if (this.isReply != true) {
        if (this.replies.length == 0 && !this.repliesLoaded) {
          this.loading = true;
          let response = axios
            .get("/feed/posts/" + parent.post + "/comments?p=" + parent.id)
            .then((response) => {
              this.replies = response.data;
              this.repliesLoaded = true;
            })
            .finally(() => {
              this.loading = false;
            });
        } else if (loadMore) {
          if (this.replies.length < parent.replies) {
            this.loading = true;
            let lastReply = this.replies[this.replies.length - 1];
            let response = axios
              .get(
                "/feed/posts/" +
                  parent.post +
                  "/comments?p=" +
                  parent.id +
                  "&s=" +
                  lastReply.id
              )
              .then((response) => {
                response.data.forEach((element) => {
                  this.replies.push(element);
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      }
    },
    postReply() {
      let data = {
        content: this.reply,
        parent: this.comment.id,
      };

      if (this.isReply) {
        data["parent"] = this.$parent.comment.id;
      }

      // Hide post button and show loading button
      this.posting = true;
      this.$refs.reply.$el.classList.add("disabled");

      axios
        .post("/feed/posts/" + this.comment.post + "/comments", data)
        .then((response) => {
          let newComment = {
            id: response.data.id,
            post: this.comment.post,
            content: escapeHtml(data["content"]),
            content_raw: data["content"],
            date: this.trans("Just now"),
            replies: 0,
            likes: 0,
            self: true,
            deletable: true,
            like: false,
            user: {
              name: this.auth_user.name,
              jobTitle: this.auth_user.jobTitle,
              company: this.auth_user.company,
              avatar: this.auth_user.avatar,
              profile_link: this.auth_user.profile_link,
            },
          };

          // Update parent object && Create new comment in list
          if (this.isReply) {
            // Replies count
            this.$parent.comment.replies++;

            // Total comments count
            this.$parent.$parent.$parent.post.comments++;
            this.$parent.$parent.$parent.$forceUpdate();

            // Create comment in list (after comment)
            this.$parent.replies.splice(
              this.$parent.replies.indexOf(this.comment) + 1,
              0,
              newComment
            );
            this.showReplies = false;
          } else {
            this.comment.replies++;
            this.$parent.$parent.post.comments++;
            this.$parent.$parent.$forceUpdate();
            this.replies.unshift(newComment);
          }

          // Clean reply field
          this.reply = "";

          // Send notification
          this.$notify({
            text: this.trans("Reply successfully posted"),
            duration: 1000,
          });
        })
        .catch((error) => {
          this.$notify({
            title: this.trans("Your reply was not created"),
            text: this.trans("Please try again later."),
            type: "error",
            duration: -1,
          });
        })
        .finally(() => {
          this.posting = false;
          if (this.$refs.reply) {
            this.$refs.reply.$el.classList.remove("disabled");
          }
        });
    },
    editComment() {
      this.$refs.editContent.$el.classList.add("disabled");
      const content = this.editContent;
      axios
        .patch(
          "/feed/posts/" + this.comment.post + "/comments/" + this.comment.id,
          {
            content: content,
          }
        )
        .then((response) => {
          // Update comment
          this.comment.content = escapeHtml(content);
          this.comment.content_raw = content;

          // Close form
          this.edit = false;

          // Send notification
          this.$notify({
            text: this.trans("Comment successfully updated"),
            duration: 1000,
          });
          this.$refs.editContent.$el.classList.remove("disabled");
        })
        .catch((error) => {
          this.$notify({
            title: this.trans("Your comment was not updated"),
            text: this.trans("Please try again later."),
            type: "error",
            duration: -1,
          });
          this.$refs.editContent.$el.classList.remove("disabled");
        });
    },
    deleteComment() {
      if (
        confirm(
          this.trans(
            "Are you sure you want to delete this comment? All replies will also be deleted"
          )
        )
      ) {
        axios
          .delete(
            "/feed/posts/" + this.comment.post + "/comments/" + this.comment.id
          )
          .then((response) => {
            // Remove comment
            if (this.isReply) {
              this.$parent.replies.splice(
                this.$parent.replies.indexOf(this.comment),
                1
              );

              // Update counts
              this.$parent.comment.replies--;
              this.$parent.$parent.$parent.post.comments--;
              this.$parent.$parent.$parent.$forceUpdate();
            } else {
              this.$parent.comments.splice(
                this.$parent.comments.indexOf(this.comment),
                1
              );

              this.comment.replies--;
              this.$parent.$parent.post.comments--;
              this.$parent.$parent.$forceUpdate();
            }

            // Send notification
            this.$notify({
              text: this.trans("Comment successfully deleted"),
              duration: 1000,
            });
          })
          .catch((error) => {
            this.$notify({
              title: this.trans("Your comment was not deleted"),
              text: this.trans("Please try again later."),
              type: "error",
              duration: -1,
            });
          });
      }
    },
    disableEdit() {
      if (this.editContent.length < 2) {
        this.$refs.btnEdit.disabled = true;
      } else {
        this.$refs.btnEdit.disabled = false;
      }
    },
    likeComment() {
      let action = this.comment.like ? "unlike" : "like";
      axios
        .post(
          "/feed/posts/" +
            this.comment.post +
            "/comments/" +
            this.comment.id +
            "/likes",
          {
            action: action,
          }
        )
        .then((res) => {
          this.comment.like = !this.comment.like;
          if (action == "like") {
            this.comment.likes++;
          } else {
            this.comment.likes--;
          }
        });
    },
    getContent() {
      let content = this.comment.content;

      // Replace links with HTML a tag
      content = content.replace(
        /((http:|https:)[^\s]+[\w])/g,
        '<a href="$1" target="_blank">$1</a>'
      );

      // Detect hashtags
      content = content.replace(
        /(^|\W)#(\w+)/g,
        '$1<a href="/feed/hashtag/$2">#$2</a>'
      );

      return content;
    },
    toggleReply() {
      this.showReplies = !this.showReplies;
      this.$nextTick(() => {
        if (this.$refs.reply && this.isReply) {
          this.$refs.reply.$el.focus();
        }
      });
    },
  },

  computed: {
    disablePost() {
      return this.reply.length < 1;
    },
  },
};
</script>

<style scoped>
.dropdown-menu i {
  margin-right: 8px;
}
</style>
