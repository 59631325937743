<template>
  <div>
    <div
      v-for="(user, index) in users"
      v-bind:key="user.id"
      v-bind:class="[
        index == 0 ? '' : 'border-top',
        'd-flex flex-column flex-lg-row py-3',
      ]"
    >
      <div class="mr-4">
        <a :href="user.profile_link">
          <img
            :src="user.avatar"
            class="rounded-circle"
            alt
            width="95"
            height="95"
          />
        </a>
      </div>
      <div class="align-self-lg-center pt-3 pt-lg-0">
        <strong>
          <a :href="user.profile_link" class="text-dark text-md">{{
            user.name
          }}</a>
        </strong>
        <span v-if="user.badges" class="badge badge-pill badge-light ml-2">{{
          user.badges[0]["name"]
        }}</span>

        <div class="text-sm">
            <span v-if="user.job_title">
                {{ user.job_title }}<span v-if="user.job_title && user.company">, </span>
            </span>
            <span v-if="user.company" class="main-color">
                {{ user.company }}
            </span>
        </div>
        <div v-if="user.location" class="location mt-0">
          <i class="fas fa-map-marker-alt"></i>
          {{ user.location }}
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["action"],

  data() {
    return {
      users: [],
      loading: true,
      stopLoading: false,
    };
  },

  beforeMount: async function () {
    const response = await axios.post(this.action);
    this.users = response.data;
    this.loading = false;
  },

  mounted() {
    window.onscroll = () => {
      if (!this.stopLoading && this.users.length > 0 && !this.loading) {
        const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 100 >
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.loading = true;
          const lastItem = this.users[this.users.length - 1];

          axios
            .post(this.action + "?s=" + lastItem.index)
            .then((response) => {
              if (response.data.length == 0) {
                this.stopLoading = true;
              } else {
                response.data.forEach((element) => {
                  this.users.push(element);
                });
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    };
  },
};
</script>
