var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "feed-post-actions bg-white block-shadow" }, [
        _c("div", { staticClass: "box-add-post" }, [
          _c("div", { staticClass: "post-zone d-flex" }, [
            _c("img", {
              staticClass: "rounded-circle ml-1",
              attrs: {
                src: this.$parent.originEntity.avatar,
                width: "32",
                height: "32"
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "align-self-center flex-1 ml-3 text-sm cursor-pointer",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#modalFeedNewPost"
                },
                on: { click: _vm.focusAddPost }
              },
              [_vm._v(_vm._s(_vm.trans("What's new with you?")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ml-auto btn btn-lm cursor-pointer",
                attrs: {
                  "data-toggle": "modal",
                  "data-target": "#modalFeedNewPost"
                },
                on: { click: _vm.focusAddPost }
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.trans("Post")) + "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-buttons" }, [
          _c(
            "a",
            {
              staticClass: "btn flex-fill",
              attrs: { href: "/feed/your-posts" }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.trans("Your posts")) + "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn flex-fill",
              attrs: {
                "data-toggle": "modal",
                "data-target": "#modalFeedNewPost"
              },
              on: {
                click: function($event) {
                  return _vm.$refs.imageUpload.click()
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-camera" }),
              _vm._v(" " + _vm._s(_vm.trans("Photo")) + "\n      ")
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn flex-fill",
              attrs: {
                "data-toggle": "modal",
                "data-target": "#modalFeedNewPost"
              },
              on: {
                click: function($event) {
                  _vm.showVideo = true
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-video" }),
              _vm._v(" " + _vm._s(_vm.trans("Video")) + "\n      ")
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn flex-fill",
              attrs: { onclick: "window.location.href = '/job/create'" }
            },
            [
              _c("i", {
                staticClass: "fas fa-briefcase d-block d-xl-inline-block"
              }),
              _vm._v(
                "\n        " + _vm._s(_vm.trans("Post a job")) + "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "modalFeedNewPost",
          staticClass: "modal modal-lm fade in",
          attrs: {
            id: "modalFeedNewPost",
            role: "dialog",
            "aria-labelledby": "modalFeedNewPostTitle",
            "aria-hidden": "true"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "button",
                  {
                    ref: "modalFeedNewPost",
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close"
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    ref: "formCreatePost",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.createPost($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [
                        _c("div", { staticClass: "d-flex p-2" }, [
                          _c("img", {
                            staticClass: "rounded-circle",
                            attrs: {
                              src: this.$parent.originEntity.avatar,
                              alt: "",
                              width: "50",
                              height: "50"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column ml-3" },
                            [
                              _c("strong", { staticClass: "text-dark" }, [
                                _vm._v(_vm._s(this.$parent.originEntity.name))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-sm" }, [
                                _vm.$parent.originEntity.jobTitle
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.$parent.originEntity.jobTitle
                                          )
                                      ),
                                      _vm.$parent.originEntity.jobTitle &&
                                      _vm.$parent.originEntity.company
                                        ? _c("span", [_vm._v(", ")])
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.originEntity.company
                                  ? _c("span", { staticClass: "main-color" }, [
                                      _vm._v(
                                        "\n                          " +
                                          _vm._s(
                                            _vm.$parent.originEntity.company
                                          ) +
                                          "\n                      "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              this.$parent.originEntity.follower_count
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "line-height-normal text-xs"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.$parent.originEntity
                                            .follower_count
                                        ) + _vm._s(_vm.trans(" followers"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("textarea-autosize", {
                          ref: "content",
                          staticClass: "comment-editor border-0 w-100 p-2",
                          attrs: {
                            placeholder: _vm.trans("Write something"),
                            "min-height": 160,
                            "max-height": 370
                          },
                          nativeOn: {
                            paste: function($event) {
                              return _vm.onPaste($event)
                            }
                          },
                          model: {
                            value: _vm.content,
                            callback: function($$v) {
                              _vm.content = $$v
                            },
                            expression: "content"
                          }
                        }),
                        _vm._v(" "),
                        _vm.previewLinkLoading
                          ? _c("div", { staticClass: "text-center text-xxs" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.trans("Please wait")) +
                                  "\n            "
                              )
                            ])
                          : _vm.previewLink != null && _vm.typePost == "text"
                          ? _c(
                              "div",
                              { staticClass: "bg-gray position-relative" },
                              [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      "d-flex": true,
                                      "flex-column":
                                        _vm.previewLink["image_orientation"] ==
                                        "landscape"
                                    },
                                    attrs: {
                                      href: _vm.previewLink["url"],
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm.previewLink["image"] != ""
                                      ? _c(
                                          "div",
                                          {
                                            class: {
                                              "w-25":
                                                _vm.previewLink[
                                                  "image_orientation"
                                                ] == "portrait"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "w-100 img-fluid",
                                              attrs: {
                                                src: _vm.previewLink["image"]
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pb-1 pt-3 px-3",
                                        class: {
                                          "w-75":
                                            _vm.previewLink[
                                              "image_orientation"
                                            ] == "portrait"
                                        }
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-lg truncate-2" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.previewLink["title"]
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-xs text-muted truncate-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.previewLink["url"]) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "remove-preview",
                                    on: { click: _vm.removePreview }
                                  },
                                  [_c("i", { staticClass: "fas fa-times" })]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "grid-image d-flex flex-wrap" },
                          _vm._l(_vm.pictures, function(picture, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "flex-grow-1" },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-times cursor-pointer remove-picture",
                                  on: {
                                    click: function($event) {
                                      return _vm.removePicture(index)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "card-img-top",
                                  attrs: { src: picture }
                                })
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.typePost == "video"
                          ? _c("div", { staticClass: "px-lg-3" }, [
                              _c("label", [_vm._v(_vm._s(_vm.trans("Video")))]),
                              _vm._v(" "),
                              _vm.videoId == null
                                ? _c("div", [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c("input", {
                                        ref: "video",
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "url",
                                          placeholder:
                                            "https://www.youtube.com/watch?v=",
                                          autofocus: ""
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "ml-3 btn btn-lm btn-md text-nowrap",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addVideo }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.trans("Add")) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "d-block" }, [
                                      _c("i", {
                                        staticClass: "fab fa-youtube"
                                      }),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.trans(
                                              "YouTube.com videos are supported"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _c("i", { staticClass: "fab fa-vimeo" }),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.trans(
                                              "Vimeo.com videos are supported"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoType == "youtube" && _vm.videoId != null
                                ? _c("div", [
                                    _c("iframe", {
                                      attrs: {
                                        width: "100%",
                                        height: "245",
                                        src:
                                          "https://www.youtube.com/embed/" +
                                          _vm.videoId,
                                        frameborder: "0",
                                        allow:
                                          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                                        allowfullscreen: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoType == "vimeo" && _vm.videoId != null
                                ? _c("div", [
                                    _c("iframe", {
                                      attrs: {
                                        src:
                                          "https://player.vimeo.com/video/" +
                                          _vm.videoId,
                                        width: "100%",
                                        height: "245",
                                        frameborder: "0",
                                        allow: "autoplay; fullscreen",
                                        allowfullscreen: ""
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoId != null
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "post-media-actions mr-4 cursor-pointer",
                                        on: { click: _vm.editVideo }
                                      },
                                      [_vm._v(_vm._s(_vm.trans("Edit")))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "post-media-actions cursor-pointer",
                                        on: { click: _vm.removeVideo }
                                      },
                                      [_vm._v(_vm._s(_vm.trans("Remove")))]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c("div", { staticClass: "mr-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: {
                              type: "button",
                              disabled: _vm.videoId != null
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.imageUpload.click()
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "post-media fas fa-camera",
                              attrs: {
                                title: _vm.trans(
                                  "Add one or multiple images to your post"
                                )
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn ml-1",
                            attrs: {
                              type: "button",
                              disabled: _vm.typePost == "photo"
                            },
                            on: {
                              click: function($event) {
                                _vm.showVideo = _vm.videoId
                                  ? _vm.showVideo
                                  : !_vm.showVideo
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "post-media fas fa-video",
                              attrs: {
                                title: _vm.trans("Add a video to your post")
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      !_vm.posting
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-lm btn-sm",
                              attrs: {
                                type: "submit",
                                disabled: _vm.disablePost
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.trans("Post")) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.posting
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-lm btn-sm",
                              attrs: { disabled: "" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.trans("Please wait")) +
                                  "\n              "
                              ),
                              _c("i", {
                                staticClass: "fas fa-circle-notch fa-spin"
                              })
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "imageUpload",
                      attrs: {
                        type: "file",
                        accept: "image/*",
                        multiple: "",
                        hidden: ""
                      },
                      on: { change: _vm.onFileChange }
                    })
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("notification-body")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }