var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bookmark" }, [
    _c("i", {
      staticClass: "far fa-bookmark",
      class: { active: _vm.bookmarked },
      attrs: { title: _vm.label },
      on: { click: _vm.toggle }
    }),
    _vm._v(" "),
    _vm.showLabel ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }