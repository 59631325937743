var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("select2", {
        attrs: {
          settings: {
            multiple: true,
            tags: true,
            ajax: _vm.ajax,
            minimumInputLength: 2,
            maximumInputLength: 50
          },
          placeholder: _vm.placeholder
        },
        on: {
          change: function($event) {
            return _vm.myChangeEvent($event)
          }
        },
        model: {
          value: _vm.values,
          callback: function($$v) {
            _vm.values = $$v
          },
          expression: "values"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }