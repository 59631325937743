<template>
  <div>
    <div
      class="modal"
      :id="'modalPostView' + post.id"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content modal-lm">
          <button
            ref="closeModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body" ref="modalBody">
            <div v-if="loading" class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            <div
              v-if="
                !loading &&
                location.length == 0 &&
                company.length == 0 &&
                role.length == 0
              "
              class="my-5 text-center"
            >
              {{ trans("No details are available for the moment.") }}
            </div>
            <div
              v-else
              class="d-flex flex-column flex-lg-row justify-content-around py-4"
            >
              <div v-if="location.length > 0" class="my-3 my-lg-0">
                <span class="h5 text-body">
                  <i class="fas fa-map-marker-alt"></i>
                  {{ trans("Views by location") }}
                </span>
                <table class="table table-sm mt-3">
                  <tr v-for="stat in location">
                    <td>{{ stat.value }}</td>
                    <td class="text-right">{{ stat.total }}</td>
                  </tr>
                </table>
              </div>
              <div v-if="company.length > 0" class="my-3 my-lg-0">
                <span class="h5 text-body">
                  <i class="fas fa-building"></i>
                  {{ trans("Views by company") }}
                </span>
                <table class="table table-sm mt-3">
                  <tr v-for="stat in company">
                    <td>{{ stat.value }}</td>
                    <td class="text-right">{{ stat.total }}</td>
                  </tr>
                </table>
              </div>
              <div v-if="role.length > 0" class="my-3 my-lg-0">
                <span class="h5 text-body">
                  <i class="fas fa-briefcase"></i>
                  {{ trans("Views by job title") }}
                </span>
                <table class="table table-sm mt-3">
                  <tr v-for="stat in role">
                    <td>{{ stat.value }}</td>
                    <td class="text-right">{{ stat.total }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],

  data() {
    return {
      location: [],
      company: [],
      role: [],
      loading: false,
    };
  },

  mounted: function () {
    this.loading = true;
    axios
      .post("/post/" + this.post.id + "/views/stats")
      .then((response) => {
        this.location = response.data.location;
        this.company = response.data.company;
        this.role = response.data.role;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
