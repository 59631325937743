<template>
  <div>
    <select
      class="form-control"
      :id="id"
      :name="name"
      :disabled="disabled"
      :required="required"
    ></select>
  </div>
</template>

<script>
import $ from "jquery";
import "select2/dist/js/select2.full";

export default {
  name: "Select2",
  data() {
    return {
      select2: null,
    };
  },
  model: {
    event: "change",
    prop: "value",
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    value: null,
  },
  watch: {
    options(val) {
      this.setOption(val);
    },
    value(val) {
      this.setValue(val);
    },
  },
  methods: {
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: val,
      });
      this.setValue(this.value);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger("change");
    },
  },
  mounted() {
    this.select2 = $(this.$el)
      .find("select")
      .select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: this.options,
      })
      .on("select2:select select2:unselect", (ev) => {
        this.$emit("change", this.select2.val());
        this.$emit("select", ev["params"]["data"]);
      })
      .on("select2:closing", (ev) => {
        this.$emit("closing", ev);
      })
      .on("select2:close", (ev) => {
        this.$emit("close", ev);
      })
      .on("select2:opening", (ev) => {
        this.$emit("opening", ev);
      })
      .on("select2:open", (ev) => {
        this.$emit("open", ev);
      })
      .on("select2:clearing", (ev) => {
        this.$emit("clearing", ev);
      })
      .on("select2:clear", (ev) => {
        this.$emit("clear", ev);
      });
    this.setValue(this.value);
  },
  beforeDestroy() {
    this.select2.select2("destroy");
  },
};
</script>

<style>
.wrap-input-inner .select2-selection__choice__display {
  font-size: 12px;
}
.wrap-input-inner .select2-selection__choice__remove {
  background: none;
  border: none;
}
.wrap-input-inner .select2-container--default .select2-selection--multiple {
  background: none;
  border: none !important;
}
.wrap-input-inner
  .select2-container
  .select2-selection--multiple
  .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.wrap-input-inner
  .select2-container
  .select2-search--inline
  .select2-search__field {
  box-sizing: border-box;
  border: none;
  margin-top: 5px;
  margin-left: 10px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
  font-size: 12px;
  margin-bottom: 15px;
}
.wrap-input-inner .select2-results {
  font-size: 12px;
}
</style>