var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feed-comments border-bottom p-3" },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("span", [
            _c("img", {
              staticClass: "rounded-circle mr-2",
              attrs: {
                src: this.$parent.auth_user.avatar,
                alt: "",
                width: "42"
              }
            })
          ]),
          _vm._v(" "),
          _c("textarea-autosize", {
            ref: "content",
            staticClass: "comment-editor w-100 py-2 px-3",
            attrs: {
              placeholder: _vm.trans("Write a comment"),
              rows: "1",
              "max-height": 300
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-right my-2" }, [
        !_vm.posting
          ? _c(
              "button",
              {
                staticClass: "btn btn-lm btn-sm",
                attrs: { disabled: _vm.disablePost },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.postComment($event)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.trans("Comment")) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.posting
          ? _c(
              "button",
              { staticClass: "btn btn-lm btn-sm", attrs: { disabled: "" } },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.trans("Please wait")) + "\n      "
                ),
                _c("i", { staticClass: "fas fa-circle-notch fa-spin" })
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.comments, function(comment) {
        return _c("feed-comment", {
          key: comment.id,
          attrs: { comment: comment }
        })
      }),
      _vm._v(" "),
      _vm.comments.length > 0 &&
      _vm.$parent.post.threads > _vm.comments.length &&
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "text-center cursor-pointer bold-blue",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.loadComments($event)
                }
              }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.trans("Load more comments")) + "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "spinner mt-1" }, [
            _c("div", { staticClass: "bounce1" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce2" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce3" })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }