<template>
  <div>
    <div class="chat-box" v-bind:class="[show ? 'open-chat' : '']">
      <div class="chat-block">
        <div class="head">
          <div class="close" @click="close"></div>
          <div class="avatar">
            <img :src="asset('/images/chatbot/avatar.png')" />
          </div>
          <div>
            <h3>LeaddMe chatbot</h3>
          </div>
        </div>
        <div class="content">
          <div class="scroll">
            <div v-for="(item, index) in items" v-bind:key="item.id">
              <div
                v-for="(message, indexMessage) in item.messages"
                v-bind:key="message.id"
              >
                <div
                  class="item"
                  v-bind:class="[item.user ? 'you' : '']"
                  v-if="message.type == 'text'"
                >
                  <div class="avatar" v-if="indexMessage == 0">
                    <img
                      v-bind:src="[
                        item.user
                          ? userAvatar
                          : asset('/images/chatbot/avatar.png'),
                      ]"
                    />
                  </div>
                  <div v-else class="no-avatar"></div>
                  <div class="item-text-wrap">
                    <div class="item-text" v-if="!message.editing">
                      <p v-html="message.content"></p>
                    </div>
                    <div
                      @click="toggleEditInterviewAnswer(message)"
                      class="cursor-pointer"
                      v-if="message.questionId && !message.editing"
                    >
                      <i class="fas fa-pen" title="Edit"></i>
                    </div>
                    <div v-if="message.editing">
                      <textarea
                        class="form-control mb-2 no-resize"
                        rows="3"
                        v-model="message.content"
                      ></textarea>
                      <button
                        class="btn btn-primary btn-sm"
                        @click.prevent="editInterviewAnswer(message)"
                      >
                        Edit
                      </button>
                      <button
                        class="btn btn-secondary btn-sm ml-2"
                        @click="toggleEditInterviewAnswer(message)"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else-if="message.type == 'buttons'" class="list-option">
                  <a
                    href="#"
                    v-for="(button, indexButton) in message.content"
                    v-bind:key="button.id"
                    @click.prevent="
                      if (isFileUploadButton(button, indexButton)) {
                        $refs.fileInput.click();
                      } else {
                        click(button, index, indexMessage);
                      }
                    "
                  >
                    {{ button.text }}
                  </a>
                </div>
              </div>
            </div>
            <div v-if="disconnected" class="alert alert-warning">
              {{
                trans(
                  "You appear to be disconnected. Please refresh this page to login."
                )
              }}
            </div>
            <div class="spinner mt-1" v-if="loading && !disconnected">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
        <div class="bar">
          <chatbot-autocomplete
            ref="acLocation"
            v-model="acLocation"
            action="/ajax/locations-global?term=:query"
            placeholder="Write your location..."
            @hit="selectAutocompleteLocation"
            v-if="showAutocomplete && activeAutocomplete == 'location'"
            @keydown.enter.prevent.native="sendAutocomplete"
          />
          <chatbot-autocomplete
            ref="acRole"
            v-model="acRole"
            action="/ajax/get-roles?term=:query"
            placeholder="Write your job role..."
            @hit="selectAutocompleteRole"
            v-if="showAutocomplete && activeAutocomplete == 'role'"
            @keydown.enter.prevent.native="sendAutocomplete"
          />

          <div class="type-form">
            <div class="wrap-input">
              <div
                class="wrap-input-inner"
                v-if="showAutocomplete && activeAutocomplete == 'skills'"
              >
                <chatbot-autocomplete-select2
                  ref="acSkills"
                  v-model="acSkills"
                  action="/ajax/get-skills"
                  placeholder="Write your skills..."
                />
                <button
                  class="btn-send"
                  @click="sendAutocompleteSelect2"
                ></button>
              </div>
              <div
                class="wrap-input-inner"
                v-bind:class="[canSendMessage() ? '' : 'disabled']"
                v-if="!showAutocomplete"
              >
                <textarea-autosize
                  ref="query"
                  v-model="query"
                  class="textarea"
                  :placeholder="trans('Write your message...')"
                  rows="1"
                  :max-height="300"
                  :disabled="!canSendMessage()"
                  @keydown.enter.prevent.native="send"
                />
                <button class="btn-send" @click="send"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-icon" @click="open"></div>
      <input
        type="file"
        style="display: none"
        ref="fileInput"
        accept=".doc,.docx,.pdf"
        @change="fileUpload"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["userId", "userAvatar", "sessionHash"],

  data() {
    return {
      show: false,
      disconnected: false,
      loading: true,
      query: "",
      fileInput: "",
      currentJobId: 0,
      currentQuestionId: 0,
      items: [],
      showAutocomplete: false,
      activeAutocomplete: "",
      acLocation: "",
      acRole: "",
      acSkills: "",
      editingQuestion: 0,
    };
  },

  beforeMount: async function () {
    try {
      const response = await axios.get(
        "/chatbot/conversation?session=" + this.sessionHash
      );
      if (response.data.length > 0) {
        this.items = response.data;
        this.loading = false;
        this.restoreCurrentJobId();
        if (this.sessionHash != "") {
          this.show = true;
        }
      } else {
        this.execQuery("LEAD_CONSULTING_BOT");
      }
    } catch (error) {
      this.disconnected = true;
    }
  },

  methods: {
    open: function () {
      this.show = !this.show;
      this.$nextTick(() => this.scrollDown());

      setTimeout(
        function () {
          this.$refs.query.$el.focus();
        }.bind(this),
        500
      );
    },
    close: function () {
      this.show = false;
    },
    send: function (event) {
      this.query = this.query.trim();
      if (this.query == "") {
        return;
      }
      if (event && event.shiftKey === true) {
        this.query = this.query + "\n";
        return;
      }
      this.items.push({
        user: true,
        messages: [
          {
            type: "text",
            content: this.query,
            questionId: this.currentQuestionId,
          },
        ],
      });
      this.execQuery(this.query);
      this.currentQuestionId = 0;
      this.$nextTick(() => this.scrollDown());
    },
    execQuery: function (query) {
      this.loading = true;
      this.query = "";
      axios
        .post("/chatbot/query", {
          query: query,
          session: this.sessionHash,
        })
        .then((response) => {
          let newMessage = {
            user: false,
            messages: [],
          };

          // Save the question id
          if (typeof response.data.question_id !== "undefined") {
            this.currentQuestionId = response.data.question_id;
          }

          response.data.bot_response.forEach((element) => {
            if (element.type == 0) {
              // NOTE : temp code while Filament API is not fixed
              if (typeof element.speech == "object") {
                element.speech.forEach((message) => {
                  newMessage.messages.push({
                    type: "text",
                    content: message,
                  });
                });
              } else {
                newMessage.messages.push({
                  type: "text",
                  content: element.speech,
                });
              }
            } else if (element.type == 1) {
              let content = [];
              element.buttons.forEach((button) => {
                if (typeof button == "object") {
                  button.action = response.data.action;
                  content.push(button);
                } else {
                  content.push({
                    action: response.data.action,
                    text: button,
                    payload: button,
                  });
                }
              });
              newMessage.messages.push({
                type: "buttons",
                content: content,
              });
            }
          });
          if (newMessage.messages.length > 0) {
            this.items.push(newMessage);
          }

          // Autocomplete when asking for location or job title
          if (["job-search"].includes(response.data.action)) {
            this.showAutocomplete = true;
            this.activeAutocomplete = "location";
            this.$nextTick(() => {
              this.$refs.acLocation.$el.children[0].children[0].focus();
            });
          } else if (
            ["Country-selected", "job-search-name", "Change-title"].includes(
              response.data.action
            )
          ) {
            this.showAutocomplete = true;
            this.activeAutocomplete = "role";
            this.$nextTick(() => {
              this.$refs.acRole.$el.children[0].children[0].focus();
            });
          }

          // Autocomplete when the question is about skills
          newMessage.messages.forEach((element) => {
            if (
              typeof element.content === "string" &&
              element.content.startsWith("What skills do you have")
            ) {
              this.showAutocomplete = true;
              this.activeAutocomplete = "skills";
              this.$nextTick(() => {
                this.$refs.acSkills.$el.children[0].children[0].focus();
              });
            }
          });
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => this.scrollDown());
        });
    },
    click: function (button, index, indexMessage) {
      this.$refs.query.$el.focus();
      this.items.push({
        user: true,
        messages: [
          {
            type: "text",
            content: button.text,
          },
        ],
      });
      this.items[index].messages.splice(indexMessage, 1);
      this.execQuery(button.payload);

      // Save the job id
      if (!isNaN(button.payload)) {
        this.currentJobId = button.payload;
      }
    },
    scrollDown: function () {
      var container = this.$el.querySelector(".scroll");
      container.scrollTop = container.scrollHeight;
    },
    isFileUploadButton: function (button, indexButton) {
      return button.action == "finish-upload" && indexButton == 0;
    },
    fileUpload: function () {
      if (typeof this.$refs.fileInput.files[0] == "undefined") {
        return;
      }
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.$refs.fileInput.files[0]);
      formData.append("jobId", this.currentJobId);
      axios
        .post("/job-applications/upload/cv", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {})
        .catch(function () {})
        .finally(() => {
          this.loading = false;

          // Click the first button in the last message, it is supposed to be "upload CV"
          const index = this.items.length - 1;
          const indexMessage = this.items[index].messages.length - 1;
          const button = this.items[index].messages[indexMessage].content[0];
          this.click(button, index, indexMessage);

          // Reset the input file
          this.$refs.fileInput.value = "";
        });
    },
    canSendMessage: function () {
      if (
        this.loading ||
        typeof this.items == "undefined" ||
        this.items.length == 0 ||
        typeof this.items[this.items.length - 1].messages == "undefined"
      ) {
        return false;
      }

      const lastMessage = this.items[this.items.length - 1].messages[
        this.items[this.items.length - 1].messages.length - 1
      ];
      if (lastMessage["type"] == "buttons") {
        return false;
      }

      if (this.editingQuestion == 0) {
        setTimeout(
          function () {
            if (!this.showAutocomplete) {
              this.$refs.query.$el.focus();
            }
          }.bind(this),
          500
        );
      }

      return true;
    },
    restoreCurrentJobId: function () {
      for (let index = this.items.length - 1; index >= 0; index--) {
        const element = this.items[index];
        if (typeof element["jobID"] != "undefined") {
          this.currentJobId = element["jobID"];
          break;
        }
      }
    },
    selectAutocomplete: function (value) {
      this.query = value;
      this.showAutocomplete = false;
      this.send();
    },
    selectAutocompleteLocation: function () {
      this.selectAutocomplete(this.acLocation);
    },
    selectAutocompleteRole: function () {
      this.selectAutocomplete(this.acRole);
    },
    sendAutocomplete: function (evt) {
      this.query = evt.target.value;
      this.showAutocomplete = false;
      this.send();
    },
    sendAutocompleteSelect2: function (evt) {
      this.query = this.acSkills;
      this.showAutocomplete = false;
      this.send();
    },
    toggleEditInterviewAnswer: function (message) {
      message.editing = !message.editing;
      this.editingQuestion = message.editing
        ? this.editingQuestion + 1
        : this.editingQuestion - 1;
      this.$forceUpdate();
    },
    editInterviewAnswer: function (message) {
      axios
        .patch("chatbot/interview/answer", {
          job: this.currentJobId,
          question: message.questionId,
          answer: message.content,
        })
        .then((res) => {
          message.editing = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
