<template>
  <div class="feed-posts mt-4">
    <feed-post
      v-if="!error"
      v-for="post in $parent.posts"
      v-bind:key="post.id"
      :post="post"
    />
    <div
      v-if="$parent.posts.length == 0 && !loading"
      class="feed-posts-no-results mx-2 mx-lg-0"
    >
      <p v-if="this.$parent.origin != 'user'">
        {{ trans("We have no posts to display right now.") }}
      </p>
      <p v-if="this.$parent.origin == 'user' && !$parent.hashtag">
        {{ trans("Welcome to LeaddMe!") }}
      </p>
      <span
        v-if="
          this.$parent.origin == 'user' &&
          this.$parent.originEntity.type == 'member'
        "
        >{{
          trans(
            "Follow professionals, recruiters and companies in your industry for updates in your newsfeed or join a group to get started. Create your first post and share with the community."
          )
        }}</span
      >
      <span
        v-if="
          this.$parent.origin == 'user' &&
          this.$parent.originEntity.type == 'recruiter'
        "
        >{{
          trans(
            "Follow companies for hot leads and industry insights or join a group to get started. Create a post or post your first job and share with the community."
          )
        }}</span
      >
      <span v-if="$parent.hashtag">{{
        trans("You can create a post to share with the community!")
      }}</span>
    </div>
    <div v-if="error" class="alert alert-warning">
      {{
        trans(
          "We cannot display your newsfeed at the moment. Try to refresh this page."
        )
      }}
    </div>
    <div v-if="loading && !error" class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apiURL: "/feed/posts",
      loading: true,
      error: false,
      page: 1,
    };
  },

  beforeMount: async function () {
    if (this.$parent.hashtag) {
      this.apiURL = "/feed/posts/hashtag/" + this.$parent.hashtag;
    } else if (this.$parent.origin == "company") {
      this.apiURL = "/feed/posts/company/" + this.$parent.destinationId;
    } else if (this.$parent.origin == "group") {
      this.apiURL = "/feed/posts/group/" + this.$parent.destinationId;
    } else if (this.$parent.origin == "event") {
      this.apiURL = "/feed/posts/event/" + this.$parent.destinationId;
    } else if (this.$parent.origin == "saved_posts") {
      this.apiURL = "/feed/posts/saved";
    } else if (this.$parent.origin == "your_posts") {
      this.apiURL = "/feed/posts/your";
    }

    try {
      const response = await axios.get(this.apiURL);
      this.$parent.posts = response.data;
      this.loading = false;
    } catch (error) {
      this.error = true;
    }
  },

  mounted() {
    window.onscroll = () => {
      if (
        !this.$parent.stopLoading &&
        this.$parent.posts.length > 0 &&
        !this.error &&
        !this.loading
      ) {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 100 >
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.loading = true;
          this.page += 1;
          let lastPost = this.$parent.posts[this.$parent.posts.length - 1];
          axios
            .get(this.apiURL + "?p=" + this.page + "&s=" + lastPost.id)
            .catch((error) => {
              this.error = true;
            })
            .then((response) => {
              if (response.data.length == 0) {
                this.$parent.stopLoading = true;
              } else {
                response.data.forEach((element) => {
                  this.$parent.posts.push(element);
                });
              }
              this.loading = false;
            });
        }
      }
    };
  },
};
</script>
