var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.users, function(user, index) {
        return _c(
          "div",
          {
            key: user.id,
            class: [
              index == 0 ? "" : "border-top",
              "d-flex flex-column flex-lg-row py-3"
            ]
          },
          [
            _c("div", { staticClass: "mr-4" }, [
              _c("a", { attrs: { href: user.profile_link } }, [
                _c("img", {
                  staticClass: "rounded-circle",
                  attrs: {
                    src: user.avatar,
                    alt: "",
                    width: "95",
                    height: "95"
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "align-self-lg-center pt-3 pt-lg-0" }, [
              _c("strong", [
                _c(
                  "a",
                  {
                    staticClass: "text-dark text-md",
                    attrs: { href: user.profile_link }
                  },
                  [_vm._v(_vm._s(user.name))]
                )
              ]),
              _vm._v(" "),
              user.badges
                ? _c(
                    "span",
                    { staticClass: "badge badge-pill badge-light ml-2" },
                    [_vm._v(_vm._s(user.badges[0]["name"]))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text-sm" }, [
                user.job_title
                  ? _c("span", [
                      _vm._v("\n              " + _vm._s(user.job_title)),
                      user.job_title && user.company
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                user.company
                  ? _c("span", { staticClass: "main-color" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(user.company) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              user.location
                ? _c("div", { staticClass: "location mt-0" }, [
                    _c("i", { staticClass: "fas fa-map-marker-alt" }),
                    _vm._v("\n        " + _vm._s(user.location) + "\n      ")
                  ])
                : _vm._e()
            ])
          ]
        )
      }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "spinner" }, [
            _c("div", { staticClass: "bounce1" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce2" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce3" })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }