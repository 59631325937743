var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("select", {
      staticClass: "form-control",
      attrs: {
        id: _vm.id,
        name: _vm.name,
        disabled: _vm.disabled,
        required: _vm.required
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }