var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "post-comment d-flex" }, [
    _c("a", { attrs: { href: _vm.comment.user.profile_link } }, [
      _c("img", {
        staticClass: "flex-shrink-0 rounded-circle mr-2",
        attrs: {
          src: _vm.comment.user.avatar,
          alt: _vm.comment.user.name,
          width: "50",
          height: "50"
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "w-100" }, [
      _c("div", { staticClass: "comment-body p-3" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", [
            _c(
              "a",
              {
                staticClass: "user-name d-block",
                attrs: { href: _vm.comment.user.profile_link }
              },
              [_vm._v(_vm._s(_vm.comment.user.name))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-auto text-right" }, [
            _c("span", { staticClass: "comment-date mr-lg-1" }, [
              _vm._v(_vm._s(_vm.comment.date))
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass: "fas fa-ellipsis-h cursor-pointer",
              attrs: { "data-toggle": "dropdown", "data-offset": "5,10" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
              _vm.comment.self
                ? _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.edit = true
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-pen" }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.trans("Edit comment")) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.comment.deletable
                ? _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteComment($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-trash" }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.trans("Delete comment")) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.comment.self
                ? _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        href: "/contact-us?report=comment&ref=" + _vm.comment.id
                      }
                    },
                    [
                      _c("i", { staticClass: "far fa-flag mr-2" }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.trans("Report")) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "user-title mb-3 text-sm" }, [
          _vm.comment.user.job_title
            ? _c("span", [
                _vm._v("\n              " + _vm._s(_vm.comment.user.job_title)),
                _vm.comment.user.job_title && _vm.comment.user.company
                  ? _c("span", [_vm._v(", ")])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.comment.user.company
            ? _c("span", { staticClass: "main-color" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.comment.user.company) +
                    "\n          "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.edit
          ? _c("div", [
              _vm.truncated
                ? _c("span", [
                    _c("span", {
                      staticClass: "content",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.truncate(
                            _vm.getContent(),
                            _vm.textCharLimit,
                            _vm.textLineLimit
                          )
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.truncated = !_vm.truncated
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.trans("see more")))]
                    )
                  ])
                : _c("span", {
                    staticClass: "content",
                    domProps: { innerHTML: _vm._s(_vm.getContent()) }
                  })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.edit
          ? _c(
              "div",
              [
                _c("textarea-autosize", {
                  ref: "editContent",
                  staticClass: "comment-editor w-100 py-2 px-3",
                  attrs: {
                    placeholder: _vm.trans("Write a reply"),
                    rows: "1",
                    "max-height": 300
                  },
                  on: { input: _vm.disableEdit },
                  model: {
                    value: _vm.editContent,
                    callback: function($$v) {
                      _vm.editContent = $$v
                    },
                    expression: "editContent"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-right pt-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.edit = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.trans("Cancel")) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      ref: "btnEdit",
                      staticClass: "btn btn-lm btn-sm",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.editComment($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.trans("Save")) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("div", { staticClass: "pb-2" }, [
            _c(
              "span",
              {
                staticClass: "cursor-pointer ml-3",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.likeComment($event)
                  }
                }
              },
              [
                _vm.comment.like
                  ? _c("i", { staticClass: "fas fa-heart mr-1" })
                  : _c("i", { staticClass: "far fa-heart mr-1" }),
                _vm._v(" "),
                _vm.comment.likes > 0
                  ? _c("span", [
                      _vm.comment.likes < 2
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.comment.likes) +
                                _vm._s(_vm.trans(" like"))
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.comment.likes) +
                                _vm._s(_vm.trans(" likes"))
                            )
                          ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "cursor-pointer ml-3",
                on: {
                  click: function($event) {
                    _vm.toggleReply()
                    _vm.loadReplies(_vm.comment)
                  }
                }
              },
              [
                _c("i", { staticClass: "far fa-comment-alt mr-1" }),
                _vm._v(" "),
                _vm.comment.replies > 0
                  ? _c("span", [
                      _vm.comment.replies < 2
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.comment.replies) +
                                _vm._s(_vm.trans(" reply"))
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.comment.replies) +
                                _vm._s(_vm.trans(" replies"))
                            )
                          ])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.showReplies
              ? _c(
                  "div",
                  { staticClass: "d-flex mt-2" },
                  [
                    _c("span", [
                      _c("img", {
                        staticClass: "rounded-circle mr-2",
                        attrs: {
                          src: this.auth_user.avatar,
                          alt: "",
                          width: "42"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("textarea-autosize", {
                      ref: "reply",
                      staticClass: "comment-editor w-100 py-2 px-3",
                      attrs: {
                        placeholder: _vm.trans("Write a reply"),
                        rows: "1",
                        "max-height": 300
                      },
                      model: {
                        value: _vm.reply,
                        callback: function($$v) {
                          _vm.reply = $$v
                        },
                        expression: "reply"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "text-right mt-2" }, [
              _vm.showReplies && !_vm.posting
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-lm btn-sm",
                      attrs: { disabled: _vm.disablePost },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.postReply($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.trans("Reply")) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.posting
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-lm btn-sm",
                      attrs: { disabled: "" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.trans("Please wait")) +
                          "\n            "
                      ),
                      _c("i", { staticClass: "fas fa-circle-notch fa-spin" })
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.replies, function(reply) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showReplies,
                    expression: "showReplies"
                  }
                ],
                key: reply.id
              },
              [
                _c("feed-comment", {
                  attrs: { comment: reply, "is-reply": true }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.replies.length > 0 &&
          _vm.comment.replies > _vm.replies.length &&
          _vm.showReplies &&
          !_vm.loading
            ? _c(
                "div",
                {
                  staticClass: "text-center cursor-pointer bold-blue mb-3",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.loadReplies(_vm.comment, true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.trans("Load more replies")) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "spinner mt-1 mb-3" }, [
                _c("div", { staticClass: "bounce1" }),
                _vm._v(" "),
                _c("div", { staticClass: "bounce2" }),
                _vm._v(" "),
                _c("div", { staticClass: "bounce3" })
              ])
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }