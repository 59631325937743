<template>
  <div>
    <div v-if="!post.super_post">
      <div
        v-if="post.like_follow && !shared"
        class="border-bottom px-4 py-2 text-sm"
      >
        <a
          :href="post.like_follow_user.profile_link"
          class="font-weight-bold"
          >{{ post.like_follow_user.name }}</a
        >
        {{ trans(" likes this") }}
      </div>
      <div
        v-if="post.comment_follow && !shared"
        class="border-bottom px-4 py-2 text-sm"
      >
        <a
          :href="post.comment_follow_user.profile_link"
          class="font-weight-bold"
          >{{ post.comment_follow_user.name }}</a
        >
        {{ trans(" commented this") }}
      </div>
      <div class="d-flex">
        <a :href="originEntity.profile_link" class="pl-3 py-3">
          <img
            :src="originEntity.avatar"
            :alt="originEntity.name"
            width="50"
            height="50"
            class="rounded-circle mt-1"
            loading="lazy"
          />
        </a>
        <div class="d-flex flex-column ml-3 py-3">
          <span>
            <a :href="originEntity.profile_link" class="font-weight-bold">{{
              originEntity.name
            }}</a>
          </span>
          <span v-if="post.group" class="line-height-normal text-xs">
            {{ trans("Posted in the group ") }}
            <a :href="post.group.profile_link">{{ post.group.name }}</a>
          </span>
          <span v-if="post.event" class="line-height-normal text-xs">
            {{ trans("Posted in the event ") }}
            <a :href="post.event.profile_link">{{ post.event.name }}</a>
          </span>
          <span v-if="post.company" class="line-height-normal text-xs"
            >{{ post.company.follower_count }}{{ trans(" followers") }}</span
          >
        <div
            class="text-sm"
            v-if="
              !post.group && !post.event && !post.company && (post.user.job_title || post.user.company)
            ">
            <span v-if="post.user.job_title">
                {{ post.user.job_title }}<span v-if="post.user.job_title && post.user.company">, </span>
            </span>
                <span v-if="post.user.company" class="main-color">
                {{ post.user.company }}
            </span>
        </div>
          <span class="text-xs">{{ post.created }}</span>
        </div>
        <div v-if="actions" class="dropdown ml-auto">
          <i
            class="fas fa-ellipsis-v text-dark cursor-pointer p-3"
            data-toggle="dropdown"
          ></i>

          <div class="dropdown-menu dropdown-menu-right">
            <a
              v-if="post.saved"
              class="dropdown-item"
              href="#"
              @click.prevent="savePost(false)"
            >
              <i class="fas fa-bookmark mr-2"></i>
              {{ trans("Unsave this post") }}
            </a>
            <a
              v-else
              class="dropdown-item"
              href="#"
              @click.prevent="savePost(true)"
            >
              <i class="far fa-bookmark mr-2"></i>
              {{ trans("Save this post") }}
            </a>
            <a class="dropdown-item" :href="'/message/new/?post=' + post.id">
              <i class="far fa-envelope mr-2"></i>
              {{ trans("Send as a message") }}
            </a>
            <a
              v-if="post.self"
              class="dropdown-item"
              href="#"
              @click.prevent="editPost"
            >
              <i class="fas fa-pen mr-2"></i>
              {{ trans("Edit post") }}
            </a>
            <a
              v-if="post.self || post.deletable"
              class="dropdown-item"
              href="#"
              @click.prevent="deletePost"
            >
              <i class="fas fa-trash mr-2"></i>
              {{ trans("Delete post") }}
            </a>
            <div v-if="post.self" class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="copyLink('link' + post.id)"
            >
              <i class="fas fa-link mr-2"></i>
              {{ trans("Copy this link") }}
            </a>
            <a
              v-if="!post.self && post.origin == 'user news feed'"
              class="dropdown-item"
              href="#"
              @click.prevent="hidePost()"
            >
              <i class="far fa-eye-slash mr-2"></i>
              {{ trans("Hide this post") }}
            </a>
            <a
              v-if="!post.self"
              class="dropdown-item"
              :href="'/contact-us?report=post&ref=' + post.id"
            >
              <i class="far fa-flag mr-2"></i>
              {{ trans("Report") }}
            </a>
          </div>
        </div>
      </div>
      <input
        v-if="actions"
        type="hidden"
        :ref="'link' + post.id"
        :value="url + '/feed/post/' + post.id"
      />
    </div>

    <div v-if="!edit" class="p-xl-3">
      <div v-if="post.super_post" class="pt-4 pt-xl-0"></div>
      <div class="px-3 p-xl-0">
        <component
          :is="shared ? 'a' : 'span'"
          :href="shared ? post.link : null"
          :class="shared ? 'text-decoration-none' : null"
        >
          <!-- POST CONTENT (TEXT) -->
          <span v-if="truncated">
            <span
              class="post-content"
              v-html="
                $options.filters.truncate(
                  getContent(),
                  textCharLimit,
                  textLineLimit
                )
              "
            ></span>
            <span
              class="see-more cursor-pointer"
              @click.prevent="truncated = !truncated"
              >{{ trans("see more") }}</span
            >
          </span>
          <span v-else class="post-content" v-html="getContent()"></span>
        </component>
      </div>

      <!-- PICTURE GALLERY -->
      <div v-if="post.pictures > 0" class="mt-3">
        <LightGallery
          :images="pictures"
          :index="galleryIndex"
          @close="galleryIndex = null"
        />
        <div class="grid-image d-flex flex-wrap">
          <div
            class="flex-grow-1 cursor-pointer"
            @click="galleryIndex = n"
            v-for="(picture, n) in pictures.slice(0, 3)"
            :key="n"
          >
            <img class :src="picture" :key="n" loading="lazy" />
            <div v-if="n == 2 && pictures.length > 3" class="overlay">
              + {{ pictures.length - 3 }}
            </div>
          </div>
        </div>
      </div>

      <!-- VIDEO PLAYER -->
      <div v-if="post.video_id" class="mt-3">
        <div v-if="post.video_type == 'youtube' && post.video_id != null">
          <iframe
            width="100%"
            height="287"
            :src="'https://www.youtube.com/embed/' + post.video_id"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div v-if="post.video_type == 'vimeo' && post.video_id != null">
          <iframe
            :src="'https://player.vimeo.com/video/' + post.video_id"
            width="100%"
            height="287"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    actions: {
      type: Boolean,
      default: false,
    },
    shared: {
      type: Boolean,
      default: false,
    },
    edit: Boolean,
  },

  data() {
    return {
      url: window.origin,
      textCharLimit: 400,
      textLineLimit: 6,
      truncated: false,
      pictures: [],
      galleryIndex: null,
      originEntity: null,
    };
  },

  beforeMount() {
    // Truncate post content
    let lines = (this.post.content.match(/\r?\n|\r/g) || []).length + 1;
    this.truncated =
      this.post.content.length > this.textCharLimit ||
      lines > this.textLineLimit;

    // Build pictures list for gallery
    if (this.post.pictures > 0) {
      for (let index = 0; index < this.post.pictures; index++) {
        this.pictures.push(this.post.media + "/" + index + ".jpg");
      }
    }

    // Set post origin (user, company, etc)
    if (this.post.company) {
      this.originEntity = this.post.company;
    } else {
      this.originEntity = this.post.user;
    }
  },

  methods: {
    editPost() {
      this.$parent.edit = true;
    },
    copyLink($ref) {
      this.$refs[$ref].setAttribute("type", "text");
      this.$refs[$ref].select();
      try {
        if (document.execCommand("copy")) {
          this.$notify({
            text: this.trans("The link was copied to your clipboard"),
          });
        }
      } catch (error) {}
      this.$refs[$ref].setAttribute("type", "hidden");
    },
    deletePost() {
      if (confirm(this.trans("Are you sure you want to delete this post?"))) {
        axios
          .delete("/feed/posts/" + this.post.id)
          .then((response) => {
            // Remove post
            if (typeof this.$parent.$parent.$parent != "undefined") {
              this.$parent.$parent.$parent.posts.splice(
                this.$parent.$parent.$parent.posts.indexOf(this.post),
                1
              );
            } else {
              this.$parent.$el.innerHTML =
                "<div class='p-3'>" +
                this.trans("This post was successfully deleted.") +
                "</div>";
            }

            // Send notification
            this.$notify({
              text: this.trans("Post successfully deleted"),
              duration: 1000,
            });
          })
          .catch((error) => {
            console.log(error);

            this.$notify({
              title: this.trans("Your post was not deleted"),
              text: this.trans("Please try again later."),
              type: "error",
              duration: -1,
            });
          });
      }
    },
    savePost(save) {
      let action = "save";
      if (!save) {
        action = "unsave";
      }
      axios
        .post("/feed/posts/" + this.post.id + "/save", {
          action: action,
        })
        .then((response) => {
          // Send notification
          let text = this.trans("Post successfully saved");
          if (!save) {
            text = this.trans("Post successfully unsaved");
          }
          this.$notify({
            text: text,
            duration: 1000,
          });

          // Change post status
          this.post.saved = save;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hidePost() {
      axios
        .post("/feed/posts/" + this.post.id + "/hide")
        .then((response) => {
          // Send notification
          let text = this.trans("Post successfully hidden");
          this.$notify({
            text: text,
            duration: 1000,
          });

          // Remove post
          if (typeof this.$parent.$parent.$parent != "undefined") {
            this.$parent.$parent.$parent.posts.splice(
              this.$parent.$parent.$parent.posts.indexOf(this.post),
              1
            );
          } else {
            this.$parent.$el.innerHTML =
              "<div class='p-3'>" +
              this.trans("Post successfully hidden") +
              "</div>";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getContent() {
      let content = this.post.content;

      // Replace links with HTML a tag
      content = content.replace(
        /((http:|https:)[^\s]+[\w])/g,
        '<a href="$1" target="_blank">$1</a>'
      );

      // Detect hashtags
      content = content.replace(
        /(^|\W)#(\w+)/g,
        '$1<a href="/feed/hashtag/$2">#$2</a>'
      );

      return content;
    },
  },
};
</script>
