<template>
  <div class="search-filter position-relative">
    <i :class="icon"></i>
    <vue-simple-suggest
      v-model="value"
      display-attribute="text"
      value-attribute="id"
      @select="onSelect"
      :list="getSuggestionList"
      :debounce="200"
      :max-suggestions="10"
      :min-length="2"
      class="mb-3"
    >
      <div slot="misc-item-below" v-if="isLoading" class="misc-item">
        <span>{{ trans("Loading...") }}</span>
      </div>
    </vue-simple-suggest>

    <div
      v-for="item in items"
      v-bind:key="item.id"
      class="align-items-baseline d-flex"
    >
      <input
        type="checkbox"
        class="mr-2"
        :name="name + '[]'"
        :value="item.id"
        :id="'search-filter-' + name + '-' + item.id"
        :checked="item.selected"
      />
      <label :for="'search-filter-' + name + '-' + item.id">{{
        item.text
      }}</label>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
export default {
  components: {
    VueSimpleSuggest,
  },

  props: ["name", "defaultItems"],

  data() {
    return {
      value: "",
      items: [],
      isLoading: true,
      action: "",
      icon: "",
    };
  },

  mounted() {
    this.items = this.defaultItems;

    if (this.name == "country") {
      this.action = "/search/api/countries";
      this.icon = "fas fa-globe-americas";
    } else if (this.name == "location_id") {
      this.action = "/ajax/locations";
      this.icon = "fas fa-globe-americas";
    } else if (this.name == "current_company" || this.name == "company") {
      this.action = "/search/api/companies";
      this.icon = "fas fa-building";
    } else if (this.name == "industry") {
      this.action = "/search/api/industries";
      this.icon = "fas fa-th-large";
    }
  },

  methods: {
    getSuggestionList() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("term", this.value);
      const promise = fetch(this.action, {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((json) => {
          this.isLoading = false;
          return json.results;
        });

      return promise;
    },
    onSelect(selected) {
      // Maps objects cannot be observed by Vue so we need to use a normal array
      let map = new Map();
      this.items.forEach((element) => {
        map.set(element.id, element);
      });
      map.set(selected.id, {
        id: selected.id,
        text: selected.text,
        selected: true,
      });
      this.items = Array.from(map, ([name, value]) => value);
    },
  },
};
</script>

<style lang="scss">
.vue-simple-suggest > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vue-simple-suggest.designed {
  position: relative;
}

.vue-simple-suggest.designed,
.vue-simple-suggest.designed * {
  box-sizing: border-box;
}

.vue-simple-suggest.designed .input-wrapper input {
  display: block;
  width: 100%;
}

.vue-simple-suggest.designed .suggestions {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  top: calc(100% + 5px);
  border-radius: 3px;
  border: 1px solid #aaa;
  background-color: #fff;
  opacity: 1;
  z-index: 1000;
}

.vue-simple-suggest.designed .suggestions .suggest-item {
  cursor: pointer;
  user-select: none;
}

.vue-simple-suggest.designed .suggestions .suggest-item,
.vue-simple-suggest.designed .suggestions .misc-item {
  padding: 5px 10px;
}

.vue-simple-suggest.designed .suggestions .suggest-item.hover {
  background-color: #4f68e8 !important;
  color: #fff !important;
}

.vue-simple-suggest.designed .suggestions .suggest-item.selected {
  background-color: #4f68e8;
  color: #fff;
}
</style>
