<template>
  <div>
    <div
      class="modal modal-lm fade in"
      :id="'modalFeedShare' + shareId"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <button
            ref="closeModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="kill"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body" ref="modalBody">
            <!-- ACTIVE USER -->
            <div class="d-flex p-3">
              <div>
                <img
                  :src="this.authUserAvatar"
                  :alt="this.authUserName"
                  width="50"
                  height="50"
                  class="rounded-circle mt-1"
                />
              </div>
              <div class="d-flex flex-column ml-3">
                <span class="text-dark font-weight-500">{{
                  this.authUserName
                }}</span>
                  <span v-if="this.authUserJobTitle">
                    {{ this.authUserJobTitle }}<span class="line-height-normal text-xs" v-if="this.authUserJobTitle && this.authUserCompany">, </span>
                </span>
                <span v-if="this.authUserCompany" class="main-color">
                    {{ this.authUserCompany }}
                </span>
              </div>
            </div>

            <!-- POST CONTENT -->
            <textarea-autosize
              ref="content"
              v-model="content"
              class="comment-editor border-0 w-100 py-2 px-3"
              :placeholder="trans('Write something')"
              :min-height="100"
              :max-height="450"
            />

            <!-- SHARED POST CONTENT -->
            <feed-post-body
              class="share-original"
              v-if="shareType == 'post'"
              :post="post.share || post"
              :shared="true"
            />
            <!-- SHARE EVENT -->
            <div v-if="shareType == 'event'" class="share-original m-3">
              <img :src="dataShare.banner" class="img-fluid" />
              <div class="d-flex p-3">
                <a :href="dataShare.link">
                  <img
                    :src="dataShare.logo"
                    width="50"
                    height="50"
                    class="rounded-circle mt-1"
                  />
                </a>
                <div class="d-flex flex-column ml-3 text-xs">
                  <a :href="dataShare.link" class="text-nm truncate-2">{{
                    dataShare.title
                  }}</a>
                  <span>{{ dataShare.date }}</span>
                  <span>{{ dataShare.location }}</span>
                </div>
              </div>
            </div>
            <!-- SHARE JOB -->
            <div v-if="shareType == 'job'" class="share-original m-3">
              <div class="d-flex p-3">
                <a :href="dataShare.link">
                  <img
                    :src="dataShare.logo"
                    width="50"
                    height="50"
                    class="rounded-circle mt-1"
                  />
                </a>
                <div class="d-flex flex-column ml-3 text-xs">
                  <a :href="dataShare.link" class="text-nm truncate-2">{{
                    dataShare.title
                  }}</a>
                  <span>{{ dataShare.company }}</span>
                  <span>{{ dataShare.location }}</span>
                </div>
              </div>
            </div>
            <!-- SHARE COMPANY -->
            <div v-if="shareType == 'company'" class="share-original m-3">
              <img :src="dataShare.banner" class="img-fluid" />
              <div class="d-flex p-3">
                <a :href="dataShare.link">
                  <img
                    :src="dataShare.logo"
                    width="50"
                    height="50"
                    class="rounded-circle mt-1"
                  />
                </a>
                <div class="d-flex flex-column ml-3 text-xs">
                  <a :href="dataShare.link" class="text-nm truncate-2">{{
                    dataShare.title
                  }}</a>
                  <span>{{ dataShare.industry }}</span>
                </div>
              </div>
            </div>
            <!-- SHARE GROUP -->
            <div v-if="shareType == 'group'" class="share-original m-3">
              <img :src="dataShare.banner" class="img-fluid" />
              <div class="d-flex p-3">
                <a :href="dataShare.link">
                  <img
                    :src="dataShare.logo"
                    width="50"
                    height="50"
                    class="rounded-circle mt-1"
                  />
                </a>
                <div class="d-flex flex-column ml-3 text-xs">
                  <a :href="dataShare.link" class="text-nm truncate-2">{{
                    dataShare.title
                  }}</a>
                  <span v-if="dataShare.users > 1"
                    >{{ dataShare.users }}{{ trans(" members") }}</span
                  >
                </div>
              </div>
            </div>
            <!-- SHARE SHARED LINK -->
            <div v-if="shareType == 'link'" class="share-original m-3">
              <a
                :href="dataShare.url"
                target="_blank"
                :class="{
                  'd-flex': true,
                  'flex-column': dataShare.image_orientation == 'landscape',
                }"
              >
                <div
                  v-if="dataShare.image != ''"
                  :class="{
                    'w-25': dataShare.image_orientation == 'portrait',
                  }"
                >
                  <img
                    :src="dataShare.image"
                    class="img-fluid"
                    loading="lazy"
                  />
                </div>
                <div
                  class="pb-1 pt-3 px-3"
                  :class="{
                    'w-75': dataShare.image_orientation == 'portrait',
                  }"
                >
                  <p class="text-lg truncate-2">{{ dataShare.title }}</p>
                  <p class="text-xs text-muted truncate-2">
                    {{ dataShare.url }}
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!posting"
              type="submit"
              @click.prevent="sharePost"
              class="btn btn-lm btn-sm"
              :disabled="disablePost"
            >
              {{ trans("Post") }}
            </button>
            <button v-if="posting" class="btn btn-lm btn-sm" disabled>
              {{ trans("Please wait") }}
              <i class="fas fa-circle-notch fa-spin"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "post",
    "shareType",
    "shareId",
    "authUserName",
    "authUserAvatar",
    "authUserJobTitle",
    "authUserCompany",
    "shareData",
  ],

  data() {
    return {
      content: "",
      posting: false,
      dataShare: {},
    };
  },

  mounted() {
    if (this.shareData) {
      if (typeof this.shareData == "string") {
        this.dataShare = JSON.parse(this.shareData);
      } else {
        this.dataShare = this.shareData;
      }
    }
  },

  methods: {
    kill() {
      setTimeout(
        function () {
          this.$parent.enableShare = false;
        }.bind(this),
        200
      );
    },
    sharePost() {
      // Hide post button and show loading button
      this.posting = true;
      this.$refs.modalBody.classList.add("disabled");

      axios
        .post("/feed/posts", {
          content: this.content,
          share_type: this.shareType,
          share_id: this.shareId,
        })
        .then((response) => {
          // Send notification
          this.$notify({
            title: this.trans("Post successfully created"),
            text:
              '<a href="/feed/post/' +
              response.data.id +
              '">' +
              this.trans("Click here") +
              "</a> " +
              this.trans("to view your post"),
            duration: -1,
          });

          this.posting = false;
          this.$refs.closeModal.click();

          setTimeout(
            function () {
              this.$parent.enableShare = false;
              this.content = "";
              this.$refs.modalBody.classList.remove("disabled");
            }.bind(this),
            700
          );
        })
        .catch((error) => {
          this.$notify({
            title: this.trans("Your post was not created"),
            text: this.trans("Please try again later."),
            type: "error",
            duration: -1,
          });

          this.posting = false;
          this.$refs.closeModal.click();
          setTimeout(
            function () {
              this.$parent.enableShare = false;
              this.$refs.modalBody.classList.remove("disabled");
            }.bind(this),
            700
          );
        });
    },
  },

  computed: {
    disablePost() {
      return this.content.length < 1;
    },
  },
};
</script>

<style>
.modal-body {
  max-height: 600px;
  overflow-y: auto;
}
</style>
