<template>
  <div>
    <div>
      <input
        v-model="search"
        type="search"
        class="rounded-input w-100"
        :placeholder="trans('Search your connections by name')"
      />
    </div>
    <div
      v-for="(user, index) in filteredItems"
      v-bind:key="user.id"
      v-bind:class="[
        index == 0 ? '' : 'border-top',
        'd-flex flex-column flex-lg-row py-3',
      ]"
    >
      <div class="mr-4">
        <a :href="user.profile_link" target="_blank">
          <img
            :src="user.avatar"
            class="rounded-circle"
            alt
            width="70"
            height="70"
          />
        </a>
      </div>
      <div class="align-self-lg-center py-3 py-lg-0">
        <strong>
          <a
            :href="user.profile_link"
            target="_blank"
            class="text-dark text-md"
            >{{ user.name }}</a
          >
        </strong>
        <span v-if="user.badges" class="badge badge-pill badge-light ml-2">{{
          user.badges[0]["name"]
        }}</span>

        <div class="text-sm">
            <span v-if="user.job_title">
                {{ user.job_title }}<span v-if="user.job_title && user.company">, </span>
            </span>
            <span v-if="user.company" class="main-color">
                {{ user.company }}
            </span>
        </div>
        <div v-if="user.location" class="location mt-0">
          <i class="fas fa-map-marker-alt"></i>
          {{ user.location }}
        </div>
      </div>
      <div class="ml-lg-auto flex-shrink-0 pl-1 align-self-center">
        <button
          v-if="!user.invitation"
          class="btn btn-lm btn-sm"
          @click="invite(user, $event)"
        >
          {{ trans("Invite") }}
        </button>
        <button
          v-if="user.invitation"
          class="btn btn-lm btn-sm btn-outline cursor-default"
          disabled
        >
          {{ trans("Invitation sent") }}
        </button>
      </div>
    </div>
    <div v-if="users.length == 0" class="px-2 pt-3">
      {{ trans("You currently have no connection to invite") }}
    </div>
    <div v-if="loading" class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "type"],

  data() {
    return {
      users: [],
      search: "",
      usersDataUrl: "/ajax/get-connections",
      loading: true,
      stopLoading: false,
    };
  },

  beforeMount: async function () {
    this.usersDataUrl += "?" + this.type + "=" + this.id;

    const response = await axios.post(this.usersDataUrl);
    this.users = response.data;
    this.loading = false;
  },

  computed: {
    filteredItems() {
      return this.users.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },

  methods: {
    invite: function (user, event) {
      window.axios
        .post("/" + this.type + "/" + this.id + "/invite", {
          user: user.id,
        })
        .then((response) => {
          user.invitation = true;
          event.target.disabled = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  // TODO infinite scroll in modal
};
</script>
