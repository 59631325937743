<template>
  <div>
    <select2
      v-model="values"
      :settings="{
        multiple: true,
        tags: true,
        ajax: ajax,
        minimumInputLength: 2,
        maximumInputLength: 50,
      }"
      :placeholder="placeholder"
      @change="myChangeEvent($event)"
    />
  </div>
</template>

<script>
export default {
  name: "ChatbotAutocompleteSelect2",

  props: ["action", "placeholder"],

  data() {
    return {
      timeout: null,
      values: [],
      value: "",
      ajax: {
        url: this.action,
        dataType: "json",
        type: "POST",
        delay: 250,
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        processResults: function (data) {
          let results = [];
          data.results.forEach((element) => {
            results.push({ id: element.text, text: element.text });
          });
          return {
            results: results,
          };
        },
      },
    };
  },

  methods: {
    myChangeEvent(val) {
      this.value = val.join(", ");
      this.$emit("input", this.value);
    },
  },
};
</script>
