var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search"
            }
          ],
          staticClass: "rounded-input w-100",
          attrs: {
            type: "search",
            placeholder: _vm.trans("Search your connections by name")
          },
          domProps: { value: _vm.search },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm._l(_vm.filteredItems, function(user, index) {
        return _c(
          "div",
          {
            key: user.id,
            class: [
              index == 0 ? "" : "border-top",
              "d-flex flex-column flex-lg-row py-3"
            ]
          },
          [
            _c("div", { staticClass: "mr-4" }, [
              _c(
                "a",
                { attrs: { href: user.profile_link, target: "_blank" } },
                [
                  _c("img", {
                    staticClass: "rounded-circle",
                    attrs: {
                      src: user.avatar,
                      alt: "",
                      width: "70",
                      height: "70"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "align-self-lg-center py-3 py-lg-0" }, [
              _c("strong", [
                _c(
                  "a",
                  {
                    staticClass: "text-dark text-md",
                    attrs: { href: user.profile_link, target: "_blank" }
                  },
                  [_vm._v(_vm._s(user.name))]
                )
              ]),
              _vm._v(" "),
              user.badges
                ? _c(
                    "span",
                    { staticClass: "badge badge-pill badge-light ml-2" },
                    [_vm._v(_vm._s(user.badges[0]["name"]))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text-sm" }, [
                user.job_title
                  ? _c("span", [
                      _vm._v("\n              " + _vm._s(user.job_title)),
                      user.job_title && user.company
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                user.company
                  ? _c("span", { staticClass: "main-color" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(user.company) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              user.location
                ? _c("div", { staticClass: "location mt-0" }, [
                    _c("i", { staticClass: "fas fa-map-marker-alt" }),
                    _vm._v("\n        " + _vm._s(user.location) + "\n      ")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-lg-auto flex-shrink-0 pl-1 align-self-center"
              },
              [
                !user.invitation
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-lm btn-sm",
                        on: {
                          click: function($event) {
                            return _vm.invite(user, $event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.trans("Invite")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                user.invitation
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-lm btn-sm btn-outline cursor-default",
                        attrs: { disabled: "" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.trans("Invitation sent")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]
        )
      }),
      _vm._v(" "),
      _vm.users.length == 0
        ? _c("div", { staticClass: "px-2 pt-3" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.trans("You currently have no connection to invite")
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "spinner" }, [
            _c("div", { staticClass: "bounce1" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce2" }),
            _vm._v(" "),
            _c("div", { staticClass: "bounce3" })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }