<template>
  <div class="feed-comments border-bottom p-3">
    <div class="d-flex">
      <span>
        <img
          :src="this.$parent.auth_user.avatar"
          class="rounded-circle mr-2"
          alt
          width="42"
        />
      </span>
      <textarea-autosize
        ref="content"
        v-model="content"
        class="comment-editor w-100 py-2 px-3"
        :placeholder="trans('Write a comment')"
        rows="1"
        :max-height="300"
      />
    </div>

    <div class="text-right my-2">
      <button
        v-if="!posting"
        class="btn btn-lm btn-sm"
        @click.prevent="postComment"
        :disabled="disablePost"
      >
        {{ trans("Comment") }}
      </button>
      <button v-if="posting" class="btn btn-lm btn-sm" disabled>
        {{ trans("Please wait") }}
        <i class="fas fa-circle-notch fa-spin"></i>
      </button>
    </div>

    <feed-comment
      v-for="comment in comments"
      v-bind:key="comment.id"
      :comment="comment"
    />

    <div
      v-if="
        comments.length > 0 &&
        $parent.post.threads > comments.length &&
        !loading
      "
      class="text-center cursor-pointer bold-blue"
      @click.prevent="loadComments"
    >
      {{ trans("Load more comments") }}
    </div>
    <div class="spinner mt-1" v-if="loading">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      comments: [],
      textCharLimit: 400,
      textLineLimit: 6,
      loading: false,
      posting: false,
    };
  },

  mounted: function () {
    if (!this.$parent.commentsLoaded) {
      this.loading = true;
      axios
        .get("/feed/posts/" + this.$parent.post.id + "/comments")
        .then((response) => {
          this.comments = response.data;
          this.$parent.comments = response.data;
          this.$parent.commentsLoaded = true;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.comments = this.$parent.comments;
    }
  },

  methods: {
    postComment() {
      // Hide post button and show loading button
      this.posting = true;
      this.$refs.content.$el.classList.add("disabled");

      axios
        .post("/feed/posts/" + this.$parent.post.id + "/comments", {
          content: this.content,
        })
        .then((response) => {
          // Update parent object
          this.$parent.post.comments++;
          this.$parent.$forceUpdate();

          // Create new comment in list
          let newComment = {
            id: response.data.id,
            post: this.$parent.post.id,
            content: escapeHtml(this.content),
            content_raw: this.content,
            date: this.trans("Just now"),
            replies: 0,
            likes: 0,
            self: true,
            deletable: true,
            like: false,
            user: {
              name: this.$parent.auth_user.name,
              job_title: this.$parent.auth_user.jobTitle,
              company: this.$parent.auth_user.company,
              avatar: this.$parent.auth_user.avatar,
              profile_link: this.$parent.auth_user.profile_link,
            },
          };
          this.comments.unshift(newComment);

          // Clean comment field
          this.content = "";

          // Send notification
          this.$notify({
            text: this.trans("Comment successfully posted"),
            duration: 1000,
          });
        })
        .catch((error) => {
          this.$notify({
            title: this.trans("Your comment was not created"),
            text: this.trans("Please try again later."),
            type: "error",
            duration: -1,
          });
        })
        .finally(() => {
          this.posting = false;
          this.$refs.content.$el.classList.remove("disabled");
        });
    },
    loadComments() {
      this.loading = true;

      let lastComment = this.comments[this.comments.length - 1];

      axios
        .get(
          "/feed/posts/" +
            this.$parent.post.id +
            "/comments?s=" +
            lastComment.id
        )
        .then((response) => {
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              this.comments.push(element);
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    disablePost() {
      return this.content.length < 1;
    },
  },
};
</script>
