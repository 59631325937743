<template>
  <div class="bookmark">
    <i
      class="far fa-bookmark"
      v-bind:class="{ active: bookmarked }"
      v-on:click="toggle"
      :title="label"
    ></i>
    <span v-if="showLabel">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "BookmarkButton",

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: 0,
    },

    showLabel: {
      type: Boolean,
      default: false,
    },

    model: {
      type: String,
    },
  },

  data() {
    return {
      bookmarked: !!this.value,
    };
  },

  computed: {
    label() {
      return this.bookmarked
        ? this.trans("Remove Bookmark")
        : this.trans("Add Bookmark");
    },
  },

  methods: {
    toggle: function () {
      if (this.model && 0 !== this.id) {
        window.axios
          .post("/" + this.model + "/bookmark/" + this.id)
          .then((response) => {
            if (response.data["result"]) {
              this.bookmarked = !this.bookmarked;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bookmark {
  $_fill_color: #f9f9f9;
  $_border_color: #e6e6e6;
  $_text_color: #797979;
  $_key_color: #4f68e8;
  $_white_color: #ffffff;

  i {
    font-size: 1rem;
    width: 35px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    background: $_fill_color;
    color: $_text_color;
    border: 1px solid $_border_color;
    border-radius: 50px;
    margin-bottom: 12px;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;

      background: $_key_color;
      color: $_white_color !important;

      &.active {
        background: $_fill_color;
        color: $_text_color;
      }
    }

    &.active {
      background: $_key_color;
      color: $_white_color !important;

      &:hover {
        color: $_key_color !important;
      }
    }
  }
}
</style>
