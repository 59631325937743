var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.post.super_post
      ? _c("div", [
          _vm.post.like_follow && !_vm.shared
            ? _c("div", { staticClass: "border-bottom px-4 py-2 text-sm" }, [
                _c(
                  "a",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { href: _vm.post.like_follow_user.profile_link }
                  },
                  [_vm._v(_vm._s(_vm.post.like_follow_user.name))]
                ),
                _vm._v("\n      " + _vm._s(_vm.trans(" likes this")) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.post.comment_follow && !_vm.shared
            ? _c("div", { staticClass: "border-bottom px-4 py-2 text-sm" }, [
                _c(
                  "a",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { href: _vm.post.comment_follow_user.profile_link }
                  },
                  [_vm._v(_vm._s(_vm.post.comment_follow_user.name))]
                ),
                _vm._v(
                  "\n      " + _vm._s(_vm.trans(" commented this")) + "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "a",
              {
                staticClass: "pl-3 py-3",
                attrs: { href: _vm.originEntity.profile_link }
              },
              [
                _c("img", {
                  staticClass: "rounded-circle mt-1",
                  attrs: {
                    src: _vm.originEntity.avatar,
                    alt: _vm.originEntity.name,
                    width: "50",
                    height: "50",
                    loading: "lazy"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column ml-3 py-3" }, [
              _c("span", [
                _c(
                  "a",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { href: _vm.originEntity.profile_link }
                  },
                  [_vm._v(_vm._s(_vm.originEntity.name))]
                )
              ]),
              _vm._v(" "),
              _vm.post.group
                ? _c("span", { staticClass: "line-height-normal text-xs" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.trans("Posted in the group ")) +
                        "\n          "
                    ),
                    _c("a", { attrs: { href: _vm.post.group.profile_link } }, [
                      _vm._v(_vm._s(_vm.post.group.name))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.post.event
                ? _c("span", { staticClass: "line-height-normal text-xs" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.trans("Posted in the event ")) +
                        "\n          "
                    ),
                    _c("a", { attrs: { href: _vm.post.event.profile_link } }, [
                      _vm._v(_vm._s(_vm.post.event.name))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.post.company
                ? _c("span", { staticClass: "line-height-normal text-xs" }, [
                    _vm._v(
                      _vm._s(_vm.post.company.follower_count) +
                        _vm._s(_vm.trans(" followers"))
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.post.group &&
              !_vm.post.event &&
              !_vm.post.company &&
              (_vm.post.user.job_title || _vm.post.user.company)
                ? _c("div", { staticClass: "text-sm" }, [
                    _vm.post.user.job_title
                      ? _c("span", [
                          _vm._v(
                            "\n              " + _vm._s(_vm.post.user.job_title)
                          ),
                          _vm.post.user.job_title && _vm.post.user.company
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.post.user.company
                      ? _c("span", { staticClass: "main-color" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.post.user.company) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "text-xs" }, [
                _vm._v(_vm._s(_vm.post.created))
              ])
            ]),
            _vm._v(" "),
            _vm.actions
              ? _c("div", { staticClass: "dropdown ml-auto" }, [
                  _c("i", {
                    staticClass:
                      "fas fa-ellipsis-v text-dark cursor-pointer p-3",
                    attrs: { "data-toggle": "dropdown" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu dropdown-menu-right" },
                    [
                      _vm.post.saved
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.savePost(false)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-bookmark mr-2" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.trans("Unsave this post")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.savePost(true)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-bookmark mr-2" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.trans("Save this post")) +
                                  "\n          "
                              )
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "/message/new/?post=" + _vm.post.id }
                        },
                        [
                          _c("i", { staticClass: "far fa-envelope mr-2" }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.trans("Send as a message")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.post.self
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.editPost($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-pen mr-2" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.trans("Edit post")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.post.self || _vm.post.deletable
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.deletePost($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-trash mr-2" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.trans("Delete post")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.post.self
                        ? _c("div", { staticClass: "dropdown-divider" })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.copyLink("link" + _vm.post.id)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-link mr-2" }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.trans("Copy this link")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.post.self && _vm.post.origin == "user news feed"
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.hidePost()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-eye-slash mr-2" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.trans("Hide this post")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.post.self
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                href:
                                  "/contact-us?report=post&ref=" + _vm.post.id
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-flag mr-2" }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.trans("Report")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.actions
            ? _c("input", {
                ref: "link" + _vm.post.id,
                attrs: { type: "hidden" },
                domProps: { value: _vm.url + "/feed/post/" + _vm.post.id }
              })
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.edit
      ? _c("div", { staticClass: "p-xl-3" }, [
          _vm.post.super_post
            ? _c("div", { staticClass: "pt-4 pt-xl-0" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-3 p-xl-0" },
            [
              _c(
                _vm.shared ? "a" : "span",
                {
                  tag: "component",
                  class: _vm.shared ? "text-decoration-none" : null,
                  attrs: { href: _vm.shared ? _vm.post.link : null }
                },
                [
                  _vm.truncated
                    ? _c("span", [
                        _c("span", {
                          staticClass: "post-content",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.truncate(
                                _vm.getContent(),
                                _vm.textCharLimit,
                                _vm.textLineLimit
                              )
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "see-more cursor-pointer",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.truncated = !_vm.truncated
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.trans("see more")))]
                        )
                      ])
                    : _c("span", {
                        staticClass: "post-content",
                        domProps: { innerHTML: _vm._s(_vm.getContent()) }
                      })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.post.pictures > 0
            ? _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("LightGallery", {
                    attrs: { images: _vm.pictures, index: _vm.galleryIndex },
                    on: {
                      close: function($event) {
                        _vm.galleryIndex = null
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "grid-image d-flex flex-wrap" },
                    _vm._l(_vm.pictures.slice(0, 3), function(picture, n) {
                      return _c(
                        "div",
                        {
                          key: n,
                          staticClass: "flex-grow-1 cursor-pointer",
                          on: {
                            click: function($event) {
                              _vm.galleryIndex = n
                            }
                          }
                        },
                        [
                          _c("img", {
                            key: n,
                            attrs: { src: picture, loading: "lazy" }
                          }),
                          _vm._v(" "),
                          n == 2 && _vm.pictures.length > 3
                            ? _c("div", { staticClass: "overlay" }, [
                                _vm._v(
                                  "\n            + " +
                                    _vm._s(_vm.pictures.length - 3) +
                                    "\n          "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.post.video_id
            ? _c("div", { staticClass: "mt-3" }, [
                _vm.post.video_type == "youtube" && _vm.post.video_id != null
                  ? _c("div", [
                      _c("iframe", {
                        attrs: {
                          width: "100%",
                          height: "287",
                          src:
                            "https://www.youtube.com/embed/" +
                            _vm.post.video_id,
                          frameborder: "0",
                          allow:
                            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                          allowfullscreen: ""
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.post.video_type == "vimeo" && _vm.post.video_id != null
                  ? _c("div", [
                      _c("iframe", {
                        attrs: {
                          src:
                            "https://player.vimeo.com/video/" +
                            _vm.post.video_id,
                          width: "100%",
                          height: "287",
                          frameborder: "0",
                          allow: "autoplay; fullscreen",
                          allowfullscreen: ""
                        }
                      })
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }