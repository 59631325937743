////////////////////////
// axios configuration//
////////////////////////
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

//////////////////////
// Vue configuration//
//////////////////////
import Vue from 'vue';
import Notifications from 'vue-notification';
import LightGallery from 'vue-light-gallery';
import VueTextareaAutosize from 'vue-textarea-autosize';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

// Translation method
// see:
// https://medium.com/@serhii.matrunchyk/using-laravel-localization-with-javascript-and-vuejs-23064d0c210e
// https://medium.com/@konafets/localization-with-laravel-and-vuejs-e27068e68ee8
// https://github.com/martinlindhe/laravel-vue-i18n-generator
// https://github.com/rmariuzzo/laravel-js-localization
// https://laracasts.com/discuss/channels/vue/laravel-translation-in-vue
// https://github.com/rmariuzzo/lang.js
// https://github.com/vemcogroup/laravel-translation

// Vue.prototype.$translations = {};
// Custom function to translate in Vue files
// source: https://pineco.de/using-laravels-localization-js/
window.i18n = window.i18n || [];
Vue.mixin({
    methods: {
        trans: function (key) {
            return i18n[key] || key;
        },
        asset: function (path) {
            let prefix = document.head.querySelector('meta[name="asset-url"]').content
            return prefix + '/' + path.replace(/^\/+/, '')
        }
    }
});

// Bugsnag for logging Vue errors
if (process.env.NODE_ENV == 'production') {
    Bugsnag.start({
        apiKey: '3ccfb40647cdad34a305c0c4718c882b',
        plugins: [new BugsnagPluginVue(Vue)],
        releaseStage: process.env.NODE_ENV,
        appType: 'app_js'
    });
}

// Modules
Vue.use(Notifications);
Vue.use(LightGallery);
Vue.use(VueTextareaAutosize);

// Global components
Vue.component('notification-body', require('./components/Feed/NotificationBody.vue').default);
Vue.component('feed-new-post', require('./components/Feed/AddPost.vue').default);
Vue.component('feed-share', require('./components/Feed/Share.vue').default);
Vue.component('feed-post-body', require('./components/Feed/PostBody.vue').default);
Vue.component('feed-post', require('./components/Feed/Post.vue').default);
Vue.component('feed-posts', require('./components/Feed/Posts.vue').default);
Vue.component('feed-comment', require('./components/Feed/Comment.vue').default);
Vue.component('feed-comments', require('./components/Feed/Comments.vue').default);
Vue.component('post-views', require('./components/Feed/PostViewDetail.vue').default);
Vue.component('news-feed', require('./components/Feed/NewsFeed.vue').default);
Vue.component('bookmark-button', require('./components/BookmarkButton.vue').default);
Vue.component('user-list', require('./components/UserList.vue').default);
Vue.component('user-invite', require('./components/UserInvite.vue').default);
Vue.component('chatbot-module', require('./components/Chatbot/Module.vue').default);
Vue.component('search-filter', require('./components/SearchFilter.vue').default);
Vue.component('chatbot-autocomplete', require('./components/Chatbot/Autocomplete.vue').default);
Vue.component('chatbot-autocomplete-select2', require('./components/Chatbot/AutocompleteSelect2.vue').default);
Vue.component('select2', require('./components/Chatbot/Select2.vue').default);

// Jobs Search Components
import VuejsPaginate from 'vuejs-paginate';
Vue.component('paginate', VuejsPaginate);

Vue.filter('truncate', function (value, limitChar, limitLine) {
    let lines = (value.match(/\r?\n|\r/g) || []).length + 1;
    if (lines > limitLine) {
        let exp = value.split('\n');
        value = '';

        for (let index = 0; index < limitLine; index++) {
            value = value + exp[index];
            if (index < limitLine - 1) {
                value = value + '\n';
            }
        }
        value = value + '...';
    }
    if (value.length > limitChar) {
        value = value.substring(0, (limitChar - 3)) + '...';
    }

    return value;
});

Vue.config.productionTip = false;

new Vue({
    el: '#app'
});

//////////////////////
// Common functions //
//////////////////////
window.copyToClipboard = function (e) {
    let tempItem = document.createElement('input');

    tempItem.setAttribute('type', 'text');
    tempItem.setAttribute('display', 'none');

    let content = e;
    if (e instanceof HTMLElement) {
        content = e.innerHTML;
    }

    tempItem.setAttribute('value', content);
    document.body.appendChild(tempItem);

    tempItem.select();
    document.execCommand('Copy');

    tempItem.parentElement.removeChild(tempItem);

    // TODO: notification
}

window.escapeHtml = function (str) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&apos;'
    };

    return str.replace(/[&<>"']/g, function (m) {
        return map[m];
    });
}

//////////////////
// Notification //
//////////////////
$('.dropdown-notification').on('show.bs.dropdown', function () {
    if ($('.dropdown-notification .notification-badge').length > 1) {
        $.ajax({
            type: "POST",
            context: this,
            dataType: "json",
            url: "/notifications/markasread",
            data: {
                _token: token.content,
                id: document.querySelectorAll(".dropdown-notification ul > li:first-child")[0].getAttribute('data-id')
            },
        });
        $('.dropdown-notification .notification-badge').remove();
    }
});

$('[data-toggle="tooltip"]').tooltip();

////////////////////////
// Other requirements //
////////////////////////
window.Vapor = require('laravel-vapor');
