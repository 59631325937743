<template>
  <div>
    <div class="feed-post-actions bg-white block-shadow">
      <div class="box-add-post">
        <div class="post-zone d-flex">
          <img
            :src="this.$parent.originEntity.avatar"
            class="rounded-circle ml-1"
            width="32"
            height="32"
          />
          <span
            class="align-self-center flex-1 ml-3 text-sm cursor-pointer"
            data-toggle="modal"
            data-target="#modalFeedNewPost"
            @click="focusAddPost"
            >{{ trans("What's new with you?") }}</span
          >
          <button
            class="ml-auto btn btn-lm cursor-pointer"
            data-toggle="modal"
            data-target="#modalFeedNewPost"
            @click="focusAddPost"
          >
            {{ trans("Post") }}
          </button>
        </div>
      </div>
      <div class="box-buttons">
        <a class="btn flex-fill" href="/feed/your-posts">
          {{ trans("Your posts") }}
        </a>
        <button
          class="btn flex-fill"
          data-toggle="modal"
          data-target="#modalFeedNewPost"
          @click="$refs.imageUpload.click()"
        >
          <i class="fas fa-camera"></i> {{ trans("Photo") }}
        </button>
        <button
          class="btn flex-fill"
          data-toggle="modal"
          data-target="#modalFeedNewPost"
          @click="showVideo = true"
        >
          <i class="fas fa-video"></i> {{ trans("Video") }}
        </button>
        <button
          class="btn flex-fill"
          onclick="window.location.href = '/job/create'"
        >
          <i class="fas fa-briefcase d-block d-xl-inline-block"></i>
          {{ trans("Post a job") }}
        </button>
      </div>
    </div>

    <!-- MODAL NEW POST -->
    <div
      class="modal modal-lm fade in"
      ref="modalFeedNewPost"
      id="modalFeedNewPost"
      role="dialog"
      aria-labelledby="modalFeedNewPostTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <button
            ref="modalFeedNewPost"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <form @submit.prevent="createPost" ref="formCreatePost">
            <div class="modal-body">
              <div class="d-flex p-2">
                <img
                  :src="this.$parent.originEntity.avatar"
                  class="rounded-circle"
                  alt
                  width="50"
                  height="50"
                />
                <div class="d-flex flex-column ml-3">
                  <strong class="text-dark">{{
                    this.$parent.originEntity.name
                  }}</strong>
                    <div class="text-sm">
                        <span v-if="$parent.originEntity.jobTitle">
                            {{ $parent.originEntity.jobTitle }}<span v-if="$parent.originEntity.jobTitle && $parent.originEntity.company">, </span>
                        </span>
                        <span v-if="$parent.originEntity.company" class="main-color">
                            {{ $parent.originEntity.company }}
                        </span>
                    </div>
                  <span
                    v-if="this.$parent.originEntity.follower_count"
                    class="line-height-normal text-xs"
                    >{{ this.$parent.originEntity.follower_count
                    }}{{ trans(" followers") }}</span
                  >
                </div>
              </div>
              <textarea-autosize
                ref="content"
                v-model="content"
                class="comment-editor border-0 w-100 p-2"
                :placeholder="trans('Write something')"
                :min-height="160"
                :max-height="370"
                @paste.native="onPaste"
              />
              <div v-if="previewLinkLoading" class="text-center text-xxs">
                {{ trans("Please wait") }}
              </div>
              <div
                v-else-if="previewLink != null && typePost == 'text'"
                class="bg-gray position-relative"
              >
                <a
                  :href="previewLink['url']"
                  target="_blank"
                  :class="{
                    'd-flex': true,
                    'flex-column':
                      previewLink['image_orientation'] == 'landscape',
                  }"
                >
                  <div
                    v-if="previewLink['image'] != ''"
                    :class="{
                      'w-25': previewLink['image_orientation'] == 'portrait',
                    }"
                  >
                    <img :src="previewLink['image']" class="w-100 img-fluid" />
                  </div>
                  <div
                    class="pb-1 pt-3 px-3"
                    :class="{
                      'w-75': previewLink['image_orientation'] == 'portrait',
                    }"
                  >
                    <p class="text-lg truncate-2">
                      {{ previewLink["title"] }}
                    </p>
                    <p class="text-xs text-muted truncate-2">
                      {{ previewLink["url"] }}
                    </p>
                  </div>
                </a>
                <div class="remove-preview" @click="removePreview">
                  <i class="fas fa-times"></i>
                </div>
              </div>
              <div class="grid-image d-flex flex-wrap">
                <div
                  class="flex-grow-1"
                  v-for="(picture, index) in pictures"
                  :key="index"
                >
                  <i
                    class="fas fa-times cursor-pointer remove-picture"
                    @click="removePicture(index)"
                  ></i>
                  <img class="card-img-top" :src="picture" />
                </div>
              </div>
              <div v-if="typePost == 'video'" class="px-lg-3">
                <label>{{ trans("Video") }}</label>
                <div v-if="videoId == null">
                  <div class="d-flex">
                    <input
                      ref="video"
                      type="url"
                      class="form-control"
                      placeholder="https://www.youtube.com/watch?v="
                      autofocus
                    />
                    <button
                      type="button"
                      class="ml-3 btn btn-lm btn-md text-nowrap"
                      @click="addVideo"
                    >
                      {{ trans("Add") }}
                    </button>
                  </div>
                  <small class="d-block">
                    <i class="fab fa-youtube"></i>
                    {{ trans("YouTube.com videos are supported") }}
                  </small>
                  <small>
                    <i class="fab fa-vimeo"></i>
                    {{ trans("Vimeo.com videos are supported") }}
                  </small>
                </div>
                <div v-if="videoType == 'youtube' && videoId != null">
                  <iframe
                    width="100%"
                    height="245"
                    :src="'https://www.youtube.com/embed/' + videoId"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div v-if="videoType == 'vimeo' && videoId != null">
                  <iframe
                    :src="'https://player.vimeo.com/video/' + videoId"
                    width="100%"
                    height="245"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
                <div v-if="videoId != null">
                  <span
                    class="post-media-actions mr-4 cursor-pointer"
                    @click="editVideo"
                    >{{ trans("Edit") }}</span
                  >
                  <span
                    class="post-media-actions cursor-pointer"
                    @click="removeVideo"
                    >{{ trans("Remove") }}</span
                  >
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="mr-auto">
                <button
                  class="btn"
                  type="button"
                  :disabled="videoId != null"
                  @click="$refs.imageUpload.click()"
                >
                  <i
                    class="post-media fas fa-camera"
                    :title="trans('Add one or multiple images to your post')"
                  ></i>
                </button>
                <button
                  class="btn ml-1"
                  type="button"
                  :disabled="typePost == 'photo'"
                  @click="showVideo = videoId ? showVideo : !showVideo"
                >
                  <i
                    class="post-media fas fa-video"
                    :title="trans('Add a video to your post')"
                  ></i>
                </button>
              </div>
              <button
                v-if="!posting"
                type="submit"
                class="btn btn-lm btn-sm"
                :disabled="disablePost"
              >
                {{ trans("Post") }}
              </button>
              <button v-if="posting" class="btn btn-lm btn-sm" disabled>
                {{ trans("Please wait") }}
                <i class="fas fa-circle-notch fa-spin"></i>
              </button>
            </div>
            <input
              ref="imageUpload"
              type="file"
              accept="image/*"
              @change="onFileChange"
              multiple
              hidden
            />
          </form>
        </div>
      </div>
    </div>
    <notification-body></notification-body>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      posting: false,
      pictures: [],
      showVideo: false,
      videoType: null,
      videoId: null,
      videoUrl: null,
      previewLink: null,
      previewLinkLoading: false,
    };
  },
  methods: {
    focusAddPost() {
      setTimeout(
        function () {
          this.$refs.content.$el.focus();
        }.bind(this),
        700
      );
    },
    createPost() {
      // Hide post button and show loading button
      this.posting = true;
      this.$refs.formCreatePost.classList.add("disabled");

      let postData = {
        content: this.content,
        pictures: this.pictures,
        video: this.videoUrl,
      };
      if (this.$parent.origin == "company") {
        postData["company"] = this.$parent.destinationId;
      } else if (this.$parent.origin == "group") {
        postData["group"] = this.$parent.destinationId;
      } else if (this.$parent.origin == "event") {
        postData["event"] = this.$parent.destinationId;
      }

      if (this.previewLink != null) {
        postData["share_link"] = this.previewLink["url"];
      }

      axios
        .post("/feed/posts", postData)
        .then((response) => {
          // Close modal
          this.$refs.modalFeedNewPost.click();

          // Reset modal fields
          setTimeout(
            function () {
              this.content = "";
              this.pictures = [];
              this.removeVideo();
              this.posting = false;
              this.$refs.formCreatePost.classList.remove("disabled");
            }.bind(this),
            800
          );

          // Create new post in feed
          let newPost = {
            id: response.data.id,
            content: escapeHtml(this.content),
            content_raw: this.content,
            created: this.trans("Just now"),
            threads: 0,
            comments: 0,
            likes: 0,
            self: true,
            like: false,
            media: response.data.media,
            pictures: this.pictures.length,
            video_type: this.videoType,
            video_id: this.videoId,
          };
          if (this.$parent.origin == "company") {
            newPost["company"] = {
              name: this.$parent.originEntity.name,
              avatar: this.$parent.originEntity.avatar,
              profile_link: this.$parent.originEntity.profile_link,
            };
          } else {
            newPost["user"] = {
              name: this.$parent.auth_user.name,
              avatar: this.$parent.auth_user.avatar,
              profile_link: this.$parent.auth_user.profile_link,
              job_title: this.$parent.auth_user.jobTitle,
              company: this.$parent.auth_user.company,
            };
          }
          if (this.previewLink != null) {
            newPost["share"] = this.previewLink;
            newPost["share"]["type"] = "link";
            this.previewLink = null;
          }

          this.$parent.posts.unshift(newPost);

          // Send notification
          this.$notify({
            title: this.trans("Post successfully created"),
            text:
              '<a href="/feed/post/' +
              response.data.id +
              '">' +
              this.trans("Click here") +
              "</a> " +
              this.trans("to view your post"),
            duration: -1,
          });
        })
        .catch((error) => {
          this.$notify({
            title: this.trans("Your post was not created"),
            text: this.trans("Please try again later."),
            type: "error",
            duration: -1,
          });

          this.$refs.modalFeedNewPost.click();

          setTimeout(
            function () {
              this.posting = false;
              this.$refs.formCreatePost.classList.remove("disabled");
            }.bind(this),
            800
          );
        });
    },
    onFileChange(e) {
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];
        const maxWidth = 800;
        const maxHeight = 600;

        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          var canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");

          // Resize only is file is bigger than max size
          let newWidth = img.width;
          let newHeight = img.height;
          if (maxWidth < img.width || maxHeight < img.height) {
            const ratio = Math.min(
              maxWidth / img.width,
              maxHeight / img.height
            );
            newWidth *= ratio;
            newHeight *= ratio;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          this.pictures.push(canvas.toDataURL("image/jpeg", 0.5));
        };
      }
      this.$refs.imageUpload.value = "";
    },
    removePicture(index) {
      this.pictures.splice(index, 1);
    },
    addVideo() {
      const regexYoutube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const regexVimeo = /^(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([‌​0-9]{6,11})[?]?.*$/;
      if (this.$refs.video.value.match(regexYoutube)) {
        this.videoType = "youtube";
        this.videoUrl = this.$refs.video.value;
        this.videoId = this.$refs.video.value.match(regexYoutube)[1];
        return this.$refs.video.value.match(regexYoutube)[1];
      } else if (this.$refs.video.value.match(regexVimeo)) {
        this.videoType = "vimeo";
        this.videoUrl = this.$refs.video.value;
        this.videoId = this.$refs.video.value.match(regexVimeo)[5];
        return this.$refs.video.value.match(regexVimeo)[5];
      }
      this.$notify({
        text: this.trans(this.$refs.video.value + " is not a valid video link"),
        type: "error",
        duration: -1,
      });
      return false;
    },
    editVideo() {
      this.videoType = null;
      this.videoId = null;
      this.videoUrl = null;
      this.showVideo = true;
    },
    removeVideo() {
      this.videoType = null;
      this.videoId = null;
      this.videoUrl = null;
      this.showVideo = false;
    },
    onPaste(evt) {
      if (this.previewLink == null) {
        const pasted = evt.clipboardData.getData("text");

        if (pasted.substr(0, 4) == "http") {
          this.previewLinkLoading = true;
          this.previewLink = { url: pasted };
          axios.post("/ajax/lookup-link", { url: pasted }).then((response) => {
            this.previewLinkLoading = false;
            const meta = response.data;
            if (meta["title"] != "") {
              this.previewLink = meta;
            }
          });
        }
      }
    },
    removePreview() {
      this.previewLink = null;
    },
  },
  computed: {
    disablePost() {
      return this.content.length < 1;
    },
    typePost() {
      if (this.pictures.length > 0) {
        return "photo";
      } else if (this.showVideo) {
        return "video";
      }
      return "text";
    },
  },
};
</script>

<style scoped>
.post-media {
  color: #4f68e8;
  font-size: 18px;
}
.post-media-actions {
  font-size: 13px;
  color: #4f68e8;
  font-weight: bold;
}
.remove-picture {
  position: absolute;
  right: 0;
  margin: 5px;
  color: #fff;
  background: #4f68e8;
  padding: 4px;
}
.remove-preview {
  top: 0;
  right: 5px;
  position: absolute;
  font-size: 20px;
  cursor: pointer;
}
</style>
