var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3 mt-lg-0" },
    [
      _vm.cancreate == null ? _c("feed-new-post") : _vm._e(),
      _vm._v(" "),
      _c("feed-posts")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }