var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feed-post", class: [_vm.display_comments ? "" : "mb-4"] },
    [
      _c("feed-post-body", {
        attrs: { post: _vm.post, actions: true, edit: _vm.edit }
      }),
      _vm._v(" "),
      _vm.edit
        ? _c(
            "div",
            { ref: "divEdit", staticClass: "p-3" },
            [
              _c("textarea-autosize", {
                ref: "editContent",
                staticClass: "comment-editor w-100 p-2",
                attrs: {
                  placeholder: _vm.trans("Write something"),
                  "max-height": 450
                },
                on: { input: _vm.disableEdit },
                model: {
                  value: _vm.editContent,
                  callback: function($$v) {
                    _vm.editContent = $$v
                  },
                  expression: "editContent"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-right pt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.edit = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.trans("Cancel")) + "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    ref: "btnEdit",
                    staticClass: "btn btn-lm btn-sm",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.editPost($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.trans("Save")) + "\n      "
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.post.share
        ? _c(
            "div",
            [
              _vm.post.share.type == "post"
                ? _c("feed-post-body", {
                    staticClass: "share-original m-3",
                    attrs: { post: _vm.post.share, shared: true }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.post.share.type == "event"
                ? _c("div", { staticClass: "share-original m-3" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.post.share.banner, loading: "lazy" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex p-3" }, [
                      _c("a", { attrs: { href: _vm.post.share.link } }, [
                        _c("img", {
                          staticClass: "rounded-circle mt-1",
                          attrs: {
                            src: _vm.post.share.logo,
                            width: "50",
                            height: "50",
                            loading: "lazy"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column ml-3 text-xs" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-nm truncate-2",
                              attrs: { href: _vm.post.share.link }
                            },
                            [_vm._v(_vm._s(_vm.post.share.title))]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.post.share.date))]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.post.share.location))])
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.post.share.type == "job"
                ? _c("div", { staticClass: "share-original m-3" }, [
                    _c("div", { staticClass: "d-flex p-3" }, [
                      _c("a", { attrs: { href: _vm.post.share.link } }, [
                        _c("img", {
                          staticClass: "rounded-circle mt-1",
                          attrs: {
                            src: _vm.post.share.logo,
                            width: "50",
                            height: "50",
                            loading: "lazy"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column ml-3 text-xs" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-nm truncate-2",
                              attrs: { href: _vm.post.share.link }
                            },
                            [_vm._v(_vm._s(_vm.post.share.title))]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.post.share.company))]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.post.share.location))])
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.post.share.type == "company"
                ? _c("div", { staticClass: "share-original m-3" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.post.share.banner, loading: "lazy" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex p-3" }, [
                      _c("a", { attrs: { href: _vm.post.share.link } }, [
                        _c("img", {
                          staticClass: "rounded-circle mt-1",
                          attrs: {
                            src: _vm.post.share.logo,
                            width: "50",
                            height: "50",
                            loading: "lazy"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column ml-3 text-xs" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-nm truncate-2",
                              attrs: { href: _vm.post.share.link }
                            },
                            [_vm._v(_vm._s(_vm.post.share.title))]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.post.share.industry))])
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.post.share.type == "group"
                ? _c("div", { staticClass: "share-original m-3" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.post.share.banner, loading: "lazy" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex p-3" }, [
                      _c("a", { attrs: { href: _vm.post.share.link } }, [
                        _c("img", {
                          staticClass: "rounded-circle mt-1",
                          attrs: {
                            src: _vm.post.share.logo,
                            width: "50",
                            height: "50",
                            loading: "lazy"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column ml-3 text-xs" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-nm truncate-2",
                              attrs: { href: _vm.post.share.link }
                            },
                            [_vm._v(_vm._s(_vm.post.share.title))]
                          ),
                          _vm._v(" "),
                          _vm.post.share.users > 1
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.post.share.users) +
                                    _vm._s(_vm.trans(" members"))
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.post.share.type == "link"
                ? _c("div", { staticClass: "share-original m-3 bg-gray" }, [
                    _c(
                      "a",
                      {
                        class: {
                          "d-flex": true,
                          "flex-column":
                            _vm.post.share.image_orientation == "landscape"
                        },
                        attrs: { href: _vm.post.share.url, target: "_blank" }
                      },
                      [
                        _vm.post.share.image != ""
                          ? _c(
                              "div",
                              {
                                class: {
                                  "w-25":
                                    _vm.post.share.image_orientation ==
                                    "portrait"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "w-100 img-fluid",
                                  attrs: {
                                    src: _vm.post.share.image,
                                    loading: "lazy"
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pb-1 pt-3 px-3",
                            class: {
                              "w-75":
                                _vm.post.share.image_orientation == "portrait"
                            }
                          },
                          [
                            _c("p", { staticClass: "text-lg truncate-2" }, [
                              _vm._v(_vm._s(_vm.post.share.title))
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "text-xs text-muted truncate-2" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.post.share.url) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm.post.share == false
        ? _c("div", { staticClass: "share-original m-3 p-3" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.trans("Shared post was deleted by the author")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "post-actions p-3 mt-3 d-flex d-lg-block" }, [
        _c(
          "a",
          {
            staticClass: "mr-lg-4 flex-1",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.likePost($event)
              }
            }
          },
          [
            _vm.post.like
              ? _c("i", { staticClass: "fas fa-heart mr-1" })
              : _c("i", { staticClass: "far fa-heart mr-1" }),
            _vm._v("\n      " + _vm._s(_vm.trans("Like")) + "\n    ")
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "flex-1 text-center",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.showComments = !_vm.showComments
              }
            }
          },
          [
            _c("i", { staticClass: "far fa-comment-alt mr-1" }),
            _vm._v("\n      " + _vm._s(_vm.trans("Comment")) + "\n    ")
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "ml-lg-4 flex-1 text-right",
            attrs: {
              href: "#",
              "data-toggle": "modal",
              "data-target": "#modalFeedShare" + _vm.shareId
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.enableShare = true
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-share-alt mr-1" }),
            _vm._v(" "),
            _c("span", { staticClass: "align-middle" }, [
              _vm._v(_vm._s(_vm.trans("Share")))
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.post.can_view_stats && _vm.post.views > 0
        ? _c(
            "div",
            {
              staticClass: "post-counts pt-3 px-3",
              class: [
                _vm.post.likes > 0 || _vm.post.comments > 0 ? "mb-n2" : "pb-3"
              ]
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: "#",
                    "data-toggle": "modal",
                    "data-target": "#modalPostView" + _vm.post.id
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-chart-bar" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.post.views) +
                      _vm._s(_vm.trans(" views on your post")) +
                      "\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.post.self ||
      (_vm.post.self && (_vm.post.likes > 0 || _vm.post.comments > 0))
        ? _c("div", { staticClass: "post-counts d-flex p-3" }, [
            _c("div", { staticClass: "text-dark font-weight-bold" }, [
              _vm.post.likes == 0
                ? _c("span")
                : _vm.post.likes < 2
                ? _c("span", [
                    _vm._v(_vm._s(_vm.post.likes) + _vm._s(_vm.trans(" like")))
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.post.likes) + _vm._s(_vm.trans(" likes")))
                  ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-auto cursor-pointer load-comments",
                on: {
                  click: function($event) {
                    _vm.showComments = !_vm.showComments
                  }
                }
              },
              [
                _vm.post.comments == 0
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.trans("Be the first to comment")))
                    ])
                  : _vm.post.comments < 2
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.post.comments) +
                          _vm._s(_vm.trans(" comment"))
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.post.comments) +
                          _vm._s(_vm.trans(" comments"))
                      )
                    ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showComments ? _c("feed-comments") : _vm._e(),
      _vm._v(" "),
      _c("feed-share", {
        attrs: {
          post: _vm.post,
          "share-type": this.shareType,
          "share-id": this.shareId,
          "auth-user-name": this.auth_user.name,
          "auth-user-avatar": this.auth_user.avatar,
          "auth-user-job-title": this.auth_user.jobTitle,
          "auth-user-company": this.auth_user.company,
          "share-data": this.shareData
        }
      }),
      _vm._v(" "),
      _vm.post.can_view_stats && _vm.post.views > 0
        ? _c("post-views", { attrs: { post: _vm.post } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }