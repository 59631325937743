var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "modalPostView" + _vm.post.id,
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered modal-xl",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content modal-lm" }, [
              _c(
                "button",
                {
                  ref: "closeModal",
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { ref: "modalBody", staticClass: "modal-body" }, [
                _vm.loading
                  ? _c("div", { staticClass: "spinner" }, [
                      _c("div", { staticClass: "bounce1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "bounce2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "bounce3" })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loading &&
                _vm.location.length == 0 &&
                _vm.company.length == 0 &&
                _vm.role.length == 0
                  ? _c("div", { staticClass: "my-5 text-center" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.trans(
                              "No details are available for the moment."
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  : _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column flex-lg-row justify-content-around py-4"
                      },
                      [
                        _vm.location.length > 0
                          ? _c("div", { staticClass: "my-3 my-lg-0" }, [
                              _c("span", { staticClass: "h5 text-body" }, [
                                _c("i", {
                                  staticClass: "fas fa-map-marker-alt"
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.trans("Views by location")) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticClass: "table table-sm mt-3" },
                                _vm._l(_vm.location, function(stat) {
                                  return _c("tr", [
                                    _c("td", [_vm._v(_vm._s(stat.value))]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(stat.total))
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.company.length > 0
                          ? _c("div", { staticClass: "my-3 my-lg-0" }, [
                              _c("span", { staticClass: "h5 text-body" }, [
                                _c("i", { staticClass: "fas fa-building" }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.trans("Views by company")) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticClass: "table table-sm mt-3" },
                                _vm._l(_vm.company, function(stat) {
                                  return _c("tr", [
                                    _c("td", [_vm._v(_vm._s(stat.value))]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(stat.total))
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.role.length > 0
                          ? _c("div", { staticClass: "my-3 my-lg-0" }, [
                              _c("span", { staticClass: "h5 text-body" }, [
                                _c("i", { staticClass: "fas fa-briefcase" }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.trans("Views by job title")) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticClass: "table table-sm mt-3" },
                                _vm._l(_vm.role, function(stat) {
                                  return _c("tr", [
                                    _c("td", [_vm._v(_vm._s(stat.value))]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(stat.total))
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e()
                      ]
                    )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }