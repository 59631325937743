var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "chat-box", class: [_vm.show ? "open-chat" : ""] },
      [
        _c("div", { staticClass: "chat-block" }, [
          _c("div", { staticClass: "head" }, [
            _c("div", { staticClass: "close", on: { click: _vm.close } }),
            _vm._v(" "),
            _c("div", { staticClass: "avatar" }, [
              _c("img", {
                attrs: { src: _vm.asset("/images/chatbot/avatar.png") }
              })
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "scroll" },
              [
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "div",
                    { key: item.id },
                    _vm._l(item.messages, function(message, indexMessage) {
                      return _c("div", { key: message.id }, [
                        message.type == "text"
                          ? _c(
                              "div",
                              {
                                staticClass: "item",
                                class: [item.user ? "you" : ""]
                              },
                              [
                                indexMessage == 0
                                  ? _c("div", { staticClass: "avatar" }, [
                                      _c("img", {
                                        attrs: {
                                          src: [
                                            item.user
                                              ? _vm.userAvatar
                                              : _vm.asset(
                                                  "/images/chatbot/avatar.png"
                                                )
                                          ]
                                        }
                                      })
                                    ])
                                  : _c("div", { staticClass: "no-avatar" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "item-text-wrap" }, [
                                  !message.editing
                                    ? _c("div", { staticClass: "item-text" }, [
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(message.content)
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  message.questionId && !message.editing
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleEditInterviewAnswer(
                                                message
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-pen",
                                            attrs: { title: "Edit" }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  message.editing
                                    ? _c("div", [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: message.content,
                                              expression: "message.content"
                                            }
                                          ],
                                          staticClass:
                                            "form-control mb-2 no-resize",
                                          attrs: { rows: "3" },
                                          domProps: { value: message.content },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                message,
                                                "content",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary btn-sm",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.editInterviewAnswer(
                                                  message
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Edit\n                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-sm ml-2",
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleEditInterviewAnswer(
                                                  message
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Cancel\n                    "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            )
                          : message.type == "buttons"
                          ? _c(
                              "div",
                              { staticClass: "list-option" },
                              _vm._l(message.content, function(
                                button,
                                indexButton
                              ) {
                                return _c(
                                  "a",
                                  {
                                    key: button.id,
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        if (
                                          _vm.isFileUploadButton(
                                            button,
                                            indexButton
                                          )
                                        ) {
                                          _vm.$refs.fileInput.click()
                                        } else {
                                          _vm.click(button, index, indexMessage)
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(button.text) +
                                        "\n                "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                }),
                _vm._v(" "),
                _vm.disconnected
                  ? _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.trans(
                              "You appear to be disconnected. Please refresh this page to login."
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loading && !_vm.disconnected
                  ? _c("div", { staticClass: "spinner mt-1" }, [
                      _c("div", { staticClass: "bounce1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "bounce2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "bounce3" })
                    ])
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bar" },
            [
              _vm.showAutocomplete && _vm.activeAutocomplete == "location"
                ? _c("chatbot-autocomplete", {
                    ref: "acLocation",
                    attrs: {
                      action: "/ajax/locations-global?term=:query",
                      placeholder: "Write your location..."
                    },
                    on: { hit: _vm.selectAutocompleteLocation },
                    nativeOn: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.sendAutocomplete($event)
                      }
                    },
                    model: {
                      value: _vm.acLocation,
                      callback: function($$v) {
                        _vm.acLocation = $$v
                      },
                      expression: "acLocation"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showAutocomplete && _vm.activeAutocomplete == "role"
                ? _c("chatbot-autocomplete", {
                    ref: "acRole",
                    attrs: {
                      action: "/ajax/get-roles?term=:query",
                      placeholder: "Write your job role..."
                    },
                    on: { hit: _vm.selectAutocompleteRole },
                    nativeOn: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.sendAutocomplete($event)
                      }
                    },
                    model: {
                      value: _vm.acRole,
                      callback: function($$v) {
                        _vm.acRole = $$v
                      },
                      expression: "acRole"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "type-form" }, [
                _c("div", { staticClass: "wrap-input" }, [
                  _vm.showAutocomplete && _vm.activeAutocomplete == "skills"
                    ? _c(
                        "div",
                        { staticClass: "wrap-input-inner" },
                        [
                          _c("chatbot-autocomplete-select2", {
                            ref: "acSkills",
                            attrs: {
                              action: "/ajax/get-skills",
                              placeholder: "Write your skills..."
                            },
                            model: {
                              value: _vm.acSkills,
                              callback: function($$v) {
                                _vm.acSkills = $$v
                              },
                              expression: "acSkills"
                            }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "btn-send",
                            on: { click: _vm.sendAutocompleteSelect2 }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showAutocomplete
                    ? _c(
                        "div",
                        {
                          staticClass: "wrap-input-inner",
                          class: [_vm.canSendMessage() ? "" : "disabled"]
                        },
                        [
                          _c("textarea-autosize", {
                            ref: "query",
                            staticClass: "textarea",
                            attrs: {
                              placeholder: _vm.trans("Write your message..."),
                              rows: "1",
                              "max-height": 300,
                              disabled: !_vm.canSendMessage()
                            },
                            nativeOn: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                                return _vm.send($event)
                              }
                            },
                            model: {
                              value: _vm.query,
                              callback: function($$v) {
                                _vm.query = $$v
                              },
                              expression: "query"
                            }
                          }),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "btn-send",
                            on: { click: _vm.send }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "chat-icon", on: { click: _vm.open } }),
        _vm._v(" "),
        _c("input", {
          ref: "fileInput",
          staticStyle: { display: "none" },
          attrs: { type: "file", accept: ".doc,.docx,.pdf" },
          on: { change: _vm.fileUpload }
        })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h3", [_vm._v("LeaddMe chatbot")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }